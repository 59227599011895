import React, { useEffect, useState } from "react";
import { LinkCard } from "../stories/cards/LinkCard";
import Buy from "../assets/icons/Platform/Buy_tests.svg";
import LateralFlow from "../assets/icons/Platform/Lateral_Flow_Tests.svg";
import PCR from "../assets/icons/Platform/PCR_tests.svg";
import { as_title, format_date, iconHelper } from "../utils/utils";
import { myResultUrl } from "../utils/constants";
import useApis from "../services/useAPI";
import { useParams } from "react-router-dom";

const TestList = () => {
  const { id } = useParams();
  const [testData, setTestData] = useState([]);
  const { callApi, loading, serverError, resultGet } = useApis();

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        await callApi(null, myResultUrl, "GET");
      } catch (error) {
        console.error("API error:", error);
      }
    };
    fetchTestData();
  }, [id]);

  useEffect(() => {
    if (resultGet?.success) {
      setTestData(resultGet.data.results);
    }
  }, [resultGet]);

  // Loading state
  if (loading) {
    return <p className="text-muted">Loading...</p>;
  }

  // Error state
  if (serverError) {
    return <p className="text-muted">{serverError}</p>;
  }

  return (
    <div className="pt-sm-4 mt-4 mb-4">
      <div className="container activate-box-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-left py-4">My Results</h1>

            {testData.length === 0 ? (
              <div>
                <p className="text-muted">You don't have any tests yet.</p>
                <LinkCard
                  to="https://myhealthchecked.com/our-tests"
                  image={Buy}
                  title="Buy Tests"
                  button_text="Buy Tests"
                  button_append_class="my-button-class"
                >
                  <p className="text-muted">
                    If you don't yet have a test, you can buy one now.
                  </p>
                </LinkCard>
              </div>
            ) : (
              <div>
                <p className="text-muted">
                  Below is a summary of your current tests.
                </p>
                {testData.map((test, i) => (
                  <div key={i}>
                    <SummaryCard test={test} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Get label for secondary card button, depending on assessment status
function getAssessmentButtonDetails(
  assessmentID,
  canEditAssessment,
  type,
  testID,
  status
) {
  if (
    status?.toLowerCase() === "error" ||
    status?.toLowerCase() === "rejected"
  ) {
    return {
      label: type?.startsWith("wellness-")
        ? "Assessment"
        : assessmentID
        ? "Edit Assessment"
        : "Fill Assessment",
      link: `#`,
      appendClass: "disabled"
    };
  }

  // Fixed link for Wellness tests
  if (type?.startsWith("wellness-")) {
    return {
      label: "Assessment",
      link: "/assessment/wellness",
      appendClass: ""
    };
  }

  // If no assessment and it can't be edited = no label
  if (!assessmentID && !canEditAssessment) {
    return { label: "Fill Assessment", link: `#`, appendClass: "disabled" };
  }

  // If has read-only assessment, view it - Note: the API uses the testID (test-kit) ID
  if (assessmentID && !canEditAssessment) {
    return {
      label: "View Assessment",
      link: type === "" ? "/assessment" : `/assessment/${testID}`,
      appendClass: ""
    };
  }

  // If has filled assessment and can edit, offer editing - Note: the API uses the testID (test-kit) ID
  if (assessmentID && canEditAssessment) {
    return {
      label: "Edit Assessment",
      link: `/assessment/${testID}`,
      appendClass: ""
    };
  }

  // Note: the API uses the testID (test-kit) ID
  return {
    label: "Fill Assessment",
    link: `/assessment/${testID}`,
    appendClass: ""
  };
}

const SummaryCard = ({ test }) => {
  const {
    id,
    detail_link,
    type,
    type_name,
    booking_reference,
    primary_barcode,
    report_id,
    test_kit_type,
    status,
    status_name,
    status_ts,
    rejected
  } = test;
  let image = PCR;
  const isBloodTest = type?.startsWith("blood-");
  if (type?.endsWith("antigen")) image = LateralFlow;
  if (type?.startsWith("wellness-")) {
    if (type === "wellness-weight") {
      image = iconHelper("Weight");
    } else {
      image = iconHelper(type_name);
    }
  }
  if (isBloodTest) image = iconHelper(type_name);

  const {
    label: assessmentLabel,
    link: assessmentLink,
    appendClass: assessmentAppendClass
  } = getAssessmentButtonDetails(
    test.assessment_id,
    test.can_edit_assessment,
    test.type,
    test.id,
    status_name
  );

  let link = `/${detail_link}/${id}/`;

  if (status === "report-ready" && report_id) {
    link = `/my-reports/${
      (test_kit_type || "")?.startsWith("blood-") ? "blood/" : ""
    }${report_id}`;
  }

  const displayBarcode =
    test_kit_type || type?.startsWith("wellness-") ? true : false;

  let continueActivateBt = {};
  // If the test was activated but there is no test_ts, we'll show a button to continue the activation
  if (status === "activating") {
    continueActivateBt = {
      button2_text: "Return to My Activation",
      //button2_link: `/tests/private/time-administered/${primary_barcode}/${customer_id}/${id}`,
      button2_link: `/${detail_link}/resume/${id}/`,
      button2_append_class: assessmentAppendClass
    };
  }
  // if (status === "rejected") {
  //   continueActivateBt = {
  //     button2_text: "Return to My Activation",
  //     //button2_link: `/tests/private/time-administered/${primary_barcode}/${customer_id}/${id}`,
  //     button2_link: `/${detail_link}/resume/${id}/`,
  //     button2_append_class: assessmentAppendClass
  //   };
  // }

  let customerCareBtn,
    assessmentBtn = {};
  if (status === "rejected" || status === "error") {
    customerCareBtn = {
      button4_text: "Contact Customer Care",
      button4_link: "https://myhealthchecked.com/help",
      button4_target: "_blank"
    };
  } else {
    assessmentBtn = {
      button3_text: assessmentLabel,
      button3_link: assessmentLink,
      button3_append_class: assessmentAppendClass
    };
  }
  return (
    <LinkCard
      // If item has test_kit_type, redirect to /test-kits/:id, otherwise /tests/:id
      to={link}
      image={image}
      ts={format_date(status_ts)}
      title={type_name || as_title(type)}
      button_text={
        status === "report-ready" && report_id ? `View Results` : `View Test`
      }
      button_append_class={
        status === "rejected" || rejected ? `disabled ` : status
      }
      {...continueActivateBt}
      {...assessmentBtn}
      {...customerCareBtn}
    >
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">
          {displayBarcode ? "Barcode" : "Booking reference"}:
        </span>
        <code className="inline-code">
          {displayBarcode ? primary_barcode : booking_reference}
        </code>
      </div>
      <div>
        <span className="text-muted d-none d-md-inline-block mr-2">
          Status:
        </span>
        <span className="text-dark font-weight-bold">
          {(!rejected && status_name) || (rejected && "Rejected")}
        </span>
      </div>
    </LinkCard>
  );
};
export default TestList;
