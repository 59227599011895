import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { report_icons } from "./ReportImages";

// Represents an individual advice item with an icon and details
const AdviceItem = ({ label, icon, details }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    setIsOpen(false);
  }, [path]);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className="advice-item">
      <Modal show={isOpen} onHide={handleClick}>
        <Modal.Header closeButton>
          <Modal.Title>{label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{details}</Modal.Body>
      </Modal>

      <Button onClick={handleClick} className="advice-button" variant="light">
        <img
          width={70}
          height={70}
          src={icon}
          alt={label}
          className="rounded-lg"
        />
        <p className="advice-label">{label}</p>
      </Button>
    </div>
  );
};

// Displays a section of advice items
const AdviceSection = ({ advisements, items }) => {
  return (
    <div>
      <p className="advice-label">
        (Click a recommendation icon below to learn more)
      </p>
      <ListGroup horizontal>
        {items.map(i => (
          <ListGroup.Item key={i}>
            <AdviceItem
              label={advisements[i]?.name}
              icon={report_icons[i]}
              details={advisements[i]?.description}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

// Main component for displaying recommendations
const RecommendationSection = ({ advisements, advice }) => {
  return (
    <div className="recommendation-section">
      {advice?.eat_more && advice?.eat_more.length > 0 && (
        <div className="mt-3">
          <h2 className="mb-2">You should eat/drink more:</h2>
          <AdviceSection advisements={advisements} items={advice?.eat_more} />
        </div>
      )}
      {advice?.eat_less && advice?.eat_less.length > 0 && (
        <div className="mt-3">
          <h2 className="mb-2">You should eat/drink less:</h2>
          <AdviceSection advisements={advisements} items={advice?.eat_less} />
        </div>
      )}
      {advice?.exercise && advice?.exercise.length > 0 && (
        <div className="mt-3">
          <h2 className="mb-2">Exercise more:</h2>
          <AdviceSection advisements={advisements} items={advice?.exercise} />
        </div>
      )}
    </div>
  );
};

export default RecommendationSection;
