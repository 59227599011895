import React, { useEffect } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { setPasswordFields } from "../../stories/Forms/fields";
import { setPasswordSchema } from "../../stories/Forms/validationSchema";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { setPasswordUrl } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

const SetPassword = () => {
  const { callApi, loading, serverError } = useApis();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname.split("/");
  //Shows toast notification on server error
  useEffect(() => {
    if (serverError) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
  }, []);

  // Handles form submission for password reset
  const handleReset = async values => {
    const payload = {
      ...values
    };

    // Calls API to send reset password link
    const response = await callApi(
      payload,
      `${setPasswordUrl}/${params[2]}/${params[3]}/`,
      "POST"
    );

    if (response) {
      navigate("/login");
      // Shows success message if reset link has been sent successfully
      auth?.showToast(
        "Success",
        "Password reset successfully!",
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
    }
  };

  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>Reset Password</h1>

            {/* DynamicForm Component with onSubmit */}
            <DynamicForm
              fields={setPasswordFields}
              onSubmit={handleReset}
              validationSchema={setPasswordSchema}
            />

            {/* Optional error and loading state display */}
            {loading && <p>Submitting...</p>}
            {serverError && <p className="error-message">{serverError}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
