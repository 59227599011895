import { TEST_TYPES } from "./utils";
import * as yup from "yup";
import "yup-phone-lite";

// Function to create body assessment schema
export const getBodyAssessmentSchema = auth => {
  return yup.object().shape({
    height: yup
      .number()
      .min(
        auth.unitType === "Metric" ? 50 : 20,
        "Value must be greater than or equal to 50"
      )
      .max(
        auth.unitType === "Metric" ? 300 : 110,
        "Value must be less than or equal to 300"
      )
      .required("Height is required"),
    weight: yup
      .number()
      .min(
        auth.unitType === "Metric" ? 20 : 40,
        "Value must be greater than or equal to 20"
      )
      .max(
        auth.unitType === "Metric" ? 500 : 1110,
        "Value must be less than or equal to 500"
      )
      .required("Weight is required"),
    smoking: yup.string().required("Smoking status is required"),
    cigarettes_per_day: yup.string().required("Cigarette count is required"),
    alcohol: yup.string().required("Alcohol consumption status is required"),
    alcohol_units_per_week: yup
      .string()
      .required("Alcohol consumption count is required")
  });
};

export const URL_TO_TEST_TYPE = {
  cholesterol: TEST_TYPES["blood-cholesterol"],
  "energy-profile": TEST_TYPES["blood-energy"],
  "general-health": TEST_TYPES["blood-general"],
  "heart-profile": TEST_TYPES["blood-heart"],
  thyroid: "blood-thyroid", // Ensure this is consistent with your TEST_TYPES
  "vitamins-minerals": TEST_TYPES["blood-vitamins"],
  "weight-management": TEST_TYPES["blood-weight"]
};

export function getTestTypeValueFromUrl(urlValue) {
  // Check if the urlValue is a key in TEST_TYPES
  if (Object.values(TEST_TYPES).includes(urlValue)) {
    return urlValue; // Return as is if it matches a TEST_TYPES value
  }

  // Otherwise, check URL_TO_TEST_TYPE mapping
  return URL_TO_TEST_TYPE[urlValue] || "unknown"; // Return 'unknown' if no match is found
}

export const TEST_TYPE_TO_NAME = {
  [TEST_TYPES["blood-cholesterol"]]: "Cholesterol Profile Test",
  [TEST_TYPES["blood-energy"]]: "Energy Profile Test",
  [TEST_TYPES["blood-general"]]: "General Health Test",
  [TEST_TYPES["blood-heart"]]: "Heart Profile Test",
  "blood-thyroid": "Thyroid Profile Test", // Assuming 'blood-thyroid' is not part of TEST_TYPES
  [TEST_TYPES["blood-vitamins"]]: "Vitamins & Minerals Profile Test",
  [TEST_TYPES["blood-weight"]]: "Weight Management Test",
   [TEST_TYPES["dna-glucose"]]: "Glucose Management DNA Test"
};

export function getTestNameFromType(testType) {
  return TEST_TYPE_TO_NAME[testType] || "Unknown Test";
}
