import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import FormInput from "../Forms/FormInput";
import { Button } from "../Buttons/Button";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { accountFields } from "../Forms/fields";
import ToggleButtons from "../Buttons/ToggleButtons";

// Modal component with form
function ModalForm(props) {
  const [toggleValue, setToggleValue] = useState(null);
  const [genderIdentity, setGenderIdentity] = useState(null);

  // Function to generate initial form values based on the fields
  const generateInitialValues = fields => {
    const initialValues = {};
    fields.forEach(field => {
      if (
        field.type !== "button" &&
        field.type !== "submit" &&
        field.type !== "cancel"
      ) {
        initialValues[field.name] = "";
      }
    });
    return initialValues;
  };

  //Sets toggle value and gender identity when initial values change
  useEffect(() => {
    if (props.initialValues?.sex) {
      setToggleValue(props.initialValues.sex);
    }
    if (typeof props.initialValues?.identify_with_gender === "boolean") {
      setGenderIdentity(
        props.initialValues.identify_with_gender ? "true" : "false"
      );
    }
  }, [props.initialValues?.sex, props.initialValues?.identify_with_gender]);

  return (
    <Modal
      className="edit-account-details-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit account details
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={props.validationSchema}
        onSubmit={(values, { resetForm }) => {
          props.onSubmit && props.onSubmit(values);
          resetForm();
        }}
        initialValues={
          props.initialValues || generateInitialValues(accountFields)
        }
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue
        }) => (
          <>
            <Modal.Body>
              <Form noValidate className={props.className}>
                {accountFields.map((field, index) => {
                  // If the field type is "button", "submit", or "cancel", skip rendering it in the body
                  if (field.type === "submit" || field.type === "cancel") {
                    return null;
                  }

                  return (
                    <Form.Group
                      key={field.controlId}
                      controlId={field.controlId}
                    >
                      {field.name === "home_country" ? (
                        <>
                          <div className={"form-group " + field?.className}>
                            <Form.Label>{field.label}</Form.Label>

                            <Form.Control
                              as="select"
                              name={field.name}
                              value={values[field.name]}
                              className="labels"
                              onChange={e => {
                                handleChange(e);
                                const selectedCountryCode = e.target.value;
                                setFieldValue(field.name, selectedCountryCode);
                              }}
                            >

                              <option value="">-</option>
                              {props.countries.map(country => (
                                <option key={country.code} value={country.code}>
                                  {country.name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        </>
                      ) : field.type === "button-group" ? (
                        <div>
                          <div className={"form-group " + field?.className}>
                            <Form.Label className="labels">
                              {field.required ? (
                                <>
                                  {field.label}
                                  <span className="required"></span>
                                </>
                              ) : (
                                field.label
                              )}
                            </Form.Label>
                            <div
                              className={
                                props.btnSecondary
                                  ? "d-flex flex-wrap align-items-center barcode-2-btns"
                                  : props.genderButton
                                    ? "d-flex flex-wrap align-items-center btn-group toggle-btn-group"
                                    : "d-flex flex-wrap align-items-center"
                              }
                            >
                              {field?.buttons?.map(button => (
                                <Button
                                  key={button.controlId}
                                  type={button.type}
                                  size={button.size}
                                  className={button.className}
                                  variant={button.variant}
                                  label={button.label}
                                  href={button.href}
                                  active={button.active}
                                  onClick={button.onClick}
                                />
                              ))}
                            </div>
                            {field.helpText && (
                              <small className="form-text text-muted mt-2">
                                {field.helpText}
                              </small>
                            )}
                          </div>
                        </div>
                      ) : field.type === "toggle-buttons" ? (
                        <>
                          <div className="form-group toggle-btn-group">
                            <ToggleButtons
                              field={field}
                              toggleValueProp={toggleValue}
                              genderIdentityProp={genderIdentity}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                            />
                            <small className="form-text text-muted mt-2">
                              {field.helpText}
                            </small>
                          </div>
                        </>
                      ) : field.type === "help-text" ? (
                        <small className="form-text text-muted mt-2">
                          {field.helpText}
                        </small>
                      ) : field.type === "checkbox" &&
                        field.label === "Marketing consent" ? (
                        <>
                          <Form.Check
                            type="checkbox"
                            id={field.controlId}
                            label={field.label}
                            checked={values[field.name]}
                            onChange={handleChange}
                            name={field.name}
                          />
                          {field.helpText && (
                            <small className="form-text text-muted mt-2">
                              {field.helpText}
                            </small>
                          )}
                        </>
                      ) : (
                        <FormInput
                          id={`input-${field.controlId}`}
                          key={field.controlId}
                          className="form-group"
                          type={field.type}
                          {...field}
                          name={field.name}
                          value={values[field.name]}
                          onChange={e => {
                            handleChange(e);
                            if (field.type === "password") {
                              props.setPassword &&
                                props.setPassword(e.target.value);
                            }
                          }}
                          error={errors[field.name]}
                          touched={touched[field.name]}
                          image={field.image}
                          validationError={props.validationError}
                        />
                      )}
                    </Form.Group>
                  );
                })}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div className="btn-group">
                {accountFields
                  .filter(
                    field => field.type === "cancel" || field.type === "submit"
                  )
                  .map(field => (
                    <Button
                      key={field.controlId}
                      type={field.type}
                      label={field.label}
                      variant={field.variant}
                      onClick={
                        field.type === "submit" ? handleSubmit : props.onCancel
                      }
                      className={
                        field.type === "submit"
                          ? "capsule-right-button"
                          : "capsule-left-button btn-secondary"
                      }
                    />
                  ))}
              </div>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

ModalForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  className: PropTypes.string,
  validationSchema: PropTypes.object,
  countries: PropTypes.array.isRequired
};

export default ModalForm;