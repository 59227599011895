import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button } from "../stories/Buttons/Button";
import useApis from "../services/useAPI";
import { useAuth } from "../components/AuthContext/AuthContext";
import { BoxArrowInRight } from "react-bootstrap-icons";
import { logoutUrl } from "../utils/constants";
import ConfirmationModal from "../stories/Modal/ConfirmationModal";

/**
 * Handles Logout funcionality.
 * Calls logout API and clears local storage
 * @returns
 */
function Logout() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);

  const { callApi } = useApis(logoutUrl, "POST");

  const handleClick = async () => {
    await callApi();
    auth.setIsAuthenticated(false);
    localStorage.clear();
    navigate("/login");
    auth?.showToast(
      "Logged out",
      "Logged out successfully!",
      "../assets/mhc.png",
      <BoxArrowInRight />
    );
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalConfirm = async () => {
    setModalShow(false);
    await handleClick();
  };

  return (
    <>
      <Container className="text-center">
        <Button
          label="Log out"
          onClick={() => {
            setModalShow(true);
          }}
          className="logout-button btn mt-2"
        />
      </Container>
      <ConfirmationModal
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        labelOne={"Cancel"}
        labelTwo={"Logout"}
        message="Are you sure you want to logout?"
        show={modalShow}
        vairantOne={"danger"}
        variantTwo={"primary"}
      />
    </>
  );
}

export default Logout;
