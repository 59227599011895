import { useEffect } from "react";
import { useFormikContext } from "formik";
import { dirtyFromConfirmation } from "../../utils/constants";

/**
 * Ask confirmation before leaving dirty form
 * @returns
 */
const PromptIfDirty = () => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (dirty) {
        event.returnValue = dirtyFromConfirmation;
        return dirtyFromConfirmation;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dirty]);

  return null;
};

export default PromptIfDirty;
