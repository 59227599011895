import React, { useEffect, useRef } from "react";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";
import { useLocation, Navigate } from "react-router-dom";

const ConfirmAccount = () => {
  const auth = useAuth();
  const location = useLocation();
  const token = location.pathname.split("/")[2];
  const url = `/api/dashboard/activate-account/${token}/`;
  const { callApi } = useApis();

  const apiCalledRef = useRef(false);
  const [redirect, setRedirect] = React.useState(null);

  // Triggers account activation process
  useEffect(() => {
    if (token && !apiCalledRef.current) {
      apiCalledRef.current = true;
  
      (async () => {
        const response = await callApi(null, url, "GET");
        debugger
        console.log("response", response)
        if (response?.success && response?.data) {
          console.log("Account activation successful:", response);
          auth.setTokens(
            response.data.jwt_token,
            response.data.email,
            response.data.role,
          );
          auth.setIsAuthenticated(true);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("user_id", response.data.id);
          localStorage.setItem("username", response.data.fullname);
          auth.showToast(
            "Confirmed",
            response.message,
            "../assets/mhc.png",
            <BoxArrowInRight />
          );
          setRedirect("/activate");
        } else if (response?.error) {
          console.error("API error occurred:", response.error);
          setRedirect("/create-account");
          auth.showToast(
            "Error",
            "Invalid Link",
            "../assets/mhc.png",
            <XSquare />
          );
        }
      })();
    }
  }, [token, callApi, url, auth]);


  // Handle redirection if needed
  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1> Create account </h1>
            <div className="lead">
              <p>
                Please check your email to confirm your new account and login.
              </p>
              <p>You can safely close this window.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
