import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { on_mobile } from "../../utils/utils";
import { RawIcon, gen_line } from "./TimelineCommon";
import Register from "../../assets/icons/Timeline/Desktop/Icons/1Register.png";
import KitDispatched from "../../assets/icons/Timeline/Desktop/Icons/3KitDispatched.png";
import ActivateKit from "../../assets/icons/Timeline/Desktop/Icons/4CollectSample.png";
import LabAnalysis from "../../assets/icons/Timeline/Desktop/Icons/6SampleReceived.png";
import Certificate from "../../assets/icons/Timeline/Desktop/Icons/7ResultsReady.png";

// Function to get the steps based on the test status
const get_steps = () => {
  const all_tests = [
    {
      label: "Test Purchased",
      match: status => status === "created",
      graphic: Register,
      pos: "a",
      instructions: () => (
        <>Your test has been purchased, we'll dispatch your kit soon</>
      )
    },
    {
      label: "Kit Dispatched",
      label_before: "Awaiting Dispatch",
      match: status => status === "dispatched",
      graphic: KitDispatched,
      pos: "b",
      instructions: () =>
        "The next step is for us to post your collection kit, it should be shipped within 2 business days."
    },
    {
      label: "Sample Collected & Kit Activated",
      label_before: "Collect Sample & Activate Kit",
      match: status => status === "activated",
      graphic: ActivateKit,
      pos: "a",
      link: "/tests/wellness/resume-activation/{test_id}/",
      instructions: ({ test_id }) => (
        <>
          <p>Your kit is in the post and should be with you soon.</p>
          <p>
            As soon as you receive your kit, you should open it, read the
            instructions and activate it, before returning it to us.
          </p>
          <Link
            to={`/tests/wellness/resume-activation/${test_id}/`}
            className="btn btn-primary"
          >
            Activate Test Kit
          </Link>
        </>
      )
    },
    {
      label: "Sample Received",
      label_before: "Awaiting Sample Return",
      match: status =>
        status === "ready-for-processing" ||
        status === "processing" ||
        status === "rejected",
      failed: status => status === "rejected",
      graphic: KitDispatched,
      pos: "b",
      instructions: () => (
        <p>
          Your kit has been activated, please make sure you post it back to us
          as soon as possible.
        </p>
      )
    },
    {
      label: "Lab Analysis",
      match: () => false,
      graphic: LabAnalysis,
      pos: "b",
      instructions: () =>
        "Your sample has been received and is being processed."
    },
    {
      label: "Results Ready",
      label_before: "Get Results",
      match: status => status === "results-ready",
      graphic: Certificate,
      pos: "a"
    }
  ];
  return all_tests.filter(s => s);
};

const WellnessTimeline = ({ test }) => {
  const {
    test_id,
    status,
    details_link,
    test_type,
    test_name,
    report_id,
    id,
    test_kit_status
  } = test;
  const navigate = useNavigate();
  const steps = get_steps();
  const isMobile = on_mobile();
  const step_increment = isMobile ? 60 : 160;
  let any_failed = false;

  if (status === "in-progress" || test_kit_status === "in-progress") {
    status = "dispatched";
  }

  const results_ready =
    status === "results-ready" || test_kit_status === "results-ready";

  let report_link = `/tests`;

  if (report_id !== null && report_id !== undefined) {
    report_link = `/wellness-report/${report_id}`;
  }

  const currentStepIndex = steps.findIndex(step =>
    step.match(status ? status : test_kit_status)
  );

  const Icon = ({ step, step_index }) => {
    const failed =
      step.failed && step.failed(status ? status : test_kit_status);
    any_failed |= failed;
    const current = any_failed ? false : step_index === currentStepIndex + 1;
    const clickLink =
      current &&
      step.link &&
      step.link.replace("{test_id}", test_id ? test_id : id);
    return (
      <RawIcon
        step={step}
        onClick={clickLink ? () => navigate(clickLink) : null}
        step_index={step_index}
        complete={step_index <= currentStepIndex}
        failed={failed}
        current={current}
        step_increment={step_increment}
      />
    );
  };

  const next_step = steps[currentStepIndex + 1] || { title: () => null };
  const Instructions = next_step.instructions || (() => null);

  const line_points = gen_line(steps, step_increment)
    .map(xy => xy.join(","))
    .join(" ");

  return (
    <div className="my-3 border-0">
      {test_name && <h2>{test_name}</h2>}
      <div className="text-md-left text-muted">
        {results_ready && (
          <>
            <p>Your results are ready</p>
            <Link to={report_link}>
              <Button variant="primary">View Report</Button>
            </Link>
          </>
        )}
        <Instructions test_id={test_id ? test_id : id} />
      </div>
      {details_link && (
        <div className="mt-2">
          <Link to={`/tests/${test_id ? test_id : id}/`}>
            View test{" "}
            {status === "results-ready" || test_kit_status === "results-ready"
              ? "results"
              : "details"}
          </Link>
        </div>
      )}
      <svg
        className="progress-svg"
        width="100%"
        viewBox={isMobile ? "0 0 300 400" : "0 0 900 300"}
      >
        <polyline points={line_points} />
        {steps.map((step, i) => (
          <Icon key={i} step={step} step_index={i} />
        ))}
      </svg>
    </div>
  );
};

export default WellnessTimeline;
