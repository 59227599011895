import React, { useEffect, useState, useCallback } from 'react'
import { InlineWidget } from 'react-calendly'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useApis from '../../services/useAPI'

let eventStatus;
let attempt;
let time;

const Calendly = ({ id, event_status, updatePostData, meetingDetails }) => {

  const [schedulingLink, setSchedulingLink] = useState('')
  const [calendlyData, setCalendlyData] = useState({})
  const [user, setUser] = useState({})
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const user_id = localStorage.getItem("user_id");
  const { callApi, resultGet, isLoading, serverError, resultPost } = useApis();

  // Get link for Calendly InlineWidget
  const getEventLink = useCallback(async () => {
    const getUrl = `/api/dashboard/calendly`;
    callApi({}, getUrl, 'GET');
    setError(serverError);
    // if (resultGet.status === 500) {
    //   setError(
    //     `Oops! It seems that Calendly is experiencing some technical difficulties. Please try again in a few minutes to book your consultation slot. We apologize for any inconvenience caused. Thank you for your patience!`
    //   );
    // }

  }, []);

  useEffect(() => {
    setSchedulingLink(resultGet?.scheduling_url)
  }, [resultGet])

  // Fetch Calendly link if it has not been loaded
  useEffect(() => {
    if (!schedulingLink) getEventLink();
  }, [schedulingLink, getEventLink]);

  // Set appropriate status and attempt
  useEffect(() => {
    switch (event_status) {
      case "":
        eventStatus = "scheduled";
        attempt = "phone_1";
        break;
      case "scheduled":
        eventStatus = "reschedule_1";
        attempt = "phone_2";
        break;
      case "reschedule_1":
        eventStatus = "reschedule_2";
        attempt = "phone_3";
        break;
      default:
        break;
    }
  }, [event_status]);

  // Listen on Calendly events to check when an event is scheduled
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`api/dashboard/calendly/user_details/${user_id}`);
        const data = await response.json(); // Get the JSON data from the response
        setUser(data); // Update the state with the fetched data
      } catch (err) {
        setError("An error occurred while fetching user details.");
        console.error(err);
      }
    };
    fetchUserDetails();

    const handleMessageEvent = e => {
      if (isCalendlyEvent(e) && e.data.payload) {

        const event_uri = e.data.payload.event?.uri; // Safely access event.uri
        const invitee_uri = e.data.payload.invitee?.uri; // Safely access invitee.uri

        if (event_uri && invitee_uri) {

          setCalendlyData({
            event_uri: event_uri,
            invitee_uri: invitee_uri,
            test_kit_id: JSON.parse(id),
            status: eventStatus,
            description: attempt
          });
        } else {
          console.warn("Event or invitee URI is missing.");
        }
      }
    };

    window.addEventListener("message", handleMessageEvent);
    return () => window.removeEventListener("message", handleMessageEvent);
  }, [id, eventStatus]);

  const isCalendlyEvent = e => e.data.event && e.data.event.startsWith('calendly')

  // Save Calendly appointment details
  useEffect(() => {
    const saveAppointmentDetails = async () => {
      if (calendlyData.event_uri) {
        const postUrl = `/api/dashboard/calendly/calendly-urls/`;
        callApi(calendlyData, postUrl, 'POST');
        setError(serverError);
        // const response = await window.app.requests.post('/api/dashboard/calendly/calendly-urls/', calendlyData, {
        //   expected_status: [200, 404],
        // })
        time = meetingDetails(resultPost?.data.start_time)
        updatePostData(resultPost?.data.note, time, resultPost?.data.description, resultPost.data.status)
        setSchedulingLink('')
      }
    };
    saveAppointmentDetails();
  }, [calendlyData, meetingDetails, updatePostData]);


  return (
    <div>
      {!error && schedulingLink ? (
        <InlineWidget
          url={schedulingLink}
          prefill={{
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            customAnswers: {
              a1: `${user?.phone_number_primary?.includes("+44") ? "" : "+44"}${
                user.phone_number_primary
              }`
            }
          }}
        />
      ) : (
        error && <Alert variant="danger">{error}</Alert>
      )}
    </div>
  );
};

export default Calendly;
