import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DynamicForm from "../../stories/Forms/DynamicForm";
import useApis from "../../services/useAPI";
import { timeAdministeredSchema } from "../../stories/Forms/validationSchema";
import { timeAdministeredFields } from "../../stories/Forms/fields";

/**
 * TimeAdministered component
 *
 * This component is responsible for collecting and activating test timestamp information
 * for the test kits. It includes a form to submit the timestamp and displays warnings about
 * exposure to STIs. It handles API calls to update the test timestamp and activate the test.
 *
 * @returns {JSX.Element} The rendered component containing the form and exposure warning modal.
 */

export default function TimeAdministered() {
  const [exposureWarningOpen, setExposureWarningOpen] = useState(false);
  const testType = localStorage.getItem("type");
  const { callApi, resultPost } = useApis();
  const { primaryBarcode } = useParams();

  const navigate = useNavigate();
  const toggleExposureWarning = () => {
    setExposureWarningOpen(false);
  };
  const postApiUrl = `/api/dashboard/test-kits/timestamp/${localStorage.primary_barcode_id}/`;
  const postActivatingUrl = `/api/dashboard/activating/`;

  useEffect(() => {
    if (resultPost) {
      const activatingPayload = {
        activation_stage: "test-taken",
        code: primaryBarcode
      };
      callApi(activatingPayload, postActivatingUrl, "POST");
      navigate("/assessment/");
    }
  }, [resultPost])

  const handleSubmit = async formData => {
    const { sample_collection_date, sample_collection_time } = formData;
    const test_ts = `${sample_collection_date}T${sample_collection_time}+05:30`;
    const payload = {
      test_ts
    };
    await callApi(payload, postApiUrl, "POST");
  };

  return (
    <>
      <div className="row justify-content-center pt-4 time-administered-page">
        <div className="mt-3 p-0 col-10">
          <h1 className="mb-4">Activate your test</h1>
          <p className="text-muted">
            Test type:{" "}
            <span className="text-dark font-weight-bold">{testType}</span>
          </p>
          <div className="pt-2">
            <DynamicForm
              fields={timeAdministeredFields}
              onSubmit={handleSubmit}
              validationSchema={timeAdministeredSchema}
            />
          </div>
        </div>
      </div>
      <Modal show={exposureWarningOpen} onHide={toggleExposureWarning}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            When testing for sexually transmitted infections (STIs), some
            infections do not show up immediately after sex. The testing window,
            or the period of time after exposure you will get an accurate
            result, can vary by infection type. For the most accurate test
            results, we recommend that you follow the accurate testing windows
            listed below.
          </p>
          <p>
            <strong>Chlamydia</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>Gonorrhoea</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>HIV</strong> - 45 days after exposure
          </p>
          <p>
            <strong>Syphilis</strong> - 12 weeks after exposure
          </p>
          <p>
            If you've had high-risk exposure to either syphilis or HIV, we
            recommend repeating the test again in 12 weeks.
          </p>
          <p>
            If you are unsure about whether you are within a testing window, you
            should do a test now and another test at a later date.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={toggleExposureWarning}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
