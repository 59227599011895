import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "react-bootstrap";

/**
 * DoctorLetterModal component
 * A modal for displaying the doctor's letter with a title and content.
 *
 * @returns {JSX.Element} The rendered modal component.
 */

export function DoctorLetterModal({ isOpen, toggle, title, children }) {
  return (
    <Modal show={isOpen} onHide={toggle} centered scrollable>
      <ModalHeader closeButton>
        <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
