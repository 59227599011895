import React from "react";
import { Alert } from "react-bootstrap";
import { getDoctorImg } from "./BloodReportPage";
import "../../../assets/scss/_doctor-letter.scss";

/**
 * Generates a status label based on the overall health status.
 */
const getStatusLabel = (status, testName) => {
  if (status === "good") return `Everything looks good with your ${testName}`;
  if (status === "require-attention")
    return `Some results require attention in your ${testName}`;
  return `Your ${testName} returned some borderline results`;
};

/**
 * DoctorLetter component
 * Displays a doctor's letter including the doctor's information,
 * a warning if an assessment is missing, and the letter's main content.
 */
const DoctorLetter = ({
  doctor,
  patient,
  overallStatus,
  content,
  missingAssessment,
  testName
}) => {
  return (
    <section className="doctorLetter__container">
      <header className="doctorLetter__doctor">
        {getDoctorImg(doctor) && (
          <img src={getDoctorImg(doctor)} alt={doctor} />
        )}
        <span className="doctorLetter__doctor-name">Dr {doctor}</span>
      </header>

      {/* Show alert when assessment is missing */}
      {missingAssessment && !JSON.parse(sessionStorage.getItem("phlebotomy")) && (
        <Alert variant="warning" dismissible={false}>
          <small>
            Please note, as we have not received your Patient Health Assessment.
            Because of this, your results have not been assessed by our doctors
            in accordance with your health and lifestyle information. Therefore,
            your results are not personalised.
          </small>
        </Alert>
      )}

      {/* Main content */}
      <div className="doctorLetter__main">
        <p className="doctorLetter__lead">Dear {patient?.split(" ")[0]}</p>
        <p
          className={`doctorLetter__overview doctorLetter__overview--${overallStatus}`}
        >
          <span>{getStatusLabel(overallStatus, testName)}</span>
        </p>
        <p
          className="doctorLetter__content"
          dangerouslySetInnerHTML={{
            __html: content.replace(/(?:\r\n|\r|\n)/g, "<br />")
          }}
        ></p>
        <p className="doctorLetter__signature">
          Kind Regards, <br />
          Dr {doctor}
        </p>
      </div>
    </section>
  );
};

export default DoctorLetter;
