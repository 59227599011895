import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "../Buttons/Button";

// CleverLink - Handles internal and external links
const CleverLink = ({ to, className = "", children }) => {
  const isExternal = to?.startsWith("http");
  const linkProps = {
    className,
    ...(isExternal
      ? { href: to, target: "_blank", rel: "noopener noreferrer" }
      : { to })
  };

  return isExternal ? (
    <a {...linkProps}>{children}</a>
  ) : (
    <Link {...linkProps}>{children}</Link>
  );
};

CleverLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

// LinkCard Component
export const LinkCard = ({
  to,
  image,
  title,
  button_text,
  button_append_class = "",
  children,
  button2_link,
  button2_text,
  button2_append_class = "",
  button3_link,
  button3_text,
  button3_append_class = "",
  button4_link,
  button4_text,
  button4_target,
  ts
}) => {
  const ContainerTag = button2_link || button4_link ? "div" : CleverLink;

  return (
    <ContainerTag to={to} className="text-decoration-none text-reset">
      <div className="bg-white rounded d-flex flex-nowrap no-gutters mb-4 padded-shadow-card shadow raise">
        <div className="d-flex pe-2 col-3 mw-100px">
          <img
            className="img-fluid can-mute p-2"
            src={image}
            alt={title}
            width={100}
            height={100}
          />
        </div>
        <div className="link-title">
          {ts && <div className="text-muted mb-1">{ts}</div>}
          <h5 className="mb-2">
            <strong>{title}</strong>
          </h5>
          <div className="text-muted">{children}</div>
        </div>
        <div className="d-flex flex-column gap-2 justify-content-center me-4">
          {to?.startsWith("http") ? (
            <Button
              className={`d-none d-md-block mt-2 ${button_append_class}`}
              color="primary"
              style={{ minWidth: "160px" }}
              label={button_text}
            />
          ) : (
            <Button
              as={CleverLink}
              to={to}
              className={`d-none d-md-block mt-2 ${button_append_class}`}
              label={button_text}
            />
          )}
          {button2_link && (
            <Button
              as={CleverLink}
              to={button2_link}
              className={`mt-2 ${button2_append_class}`}
              label={button2_text}
            />
          )}
          {button3_link && (
            <Button
              as={CleverLink}
              to={button3_link}
              className={`d-none d-md-block mt-2 ${button3_append_class}`}
              label={button3_text}
            />
          )}
          {button4_link && (
            <Button
              tag="a"
              href={button4_link}
              className={`d-none d-md-block mt-2 ${button4_target}`}
              target={button4_target ? "_blank" : undefined}
              label={button4_text}
            />
          )}
        </div>
      </div>
    </ContainerTag>
  );
};

LinkCard.propTypes = {
  button2_append_class: PropTypes.string,
  button2_link: PropTypes.string,
  button2_text: PropTypes.string,
  button3_append_class: PropTypes.string,
  button3_link: PropTypes.string,
  button3_text: PropTypes.string,
  button4_link: PropTypes.string,
  button4_target: PropTypes.string,
  button4_text: PropTypes.string,
  button_append_class: PropTypes.string,
  button_text: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  ts: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
