import React from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../../assets/scss/_blood-marker-modal.scss";

const MarkerDetailQuestions = {
  what_is: "What is %markerName%?",
  what_can: "What can my %markerName% tell me about my health?",
  meaning: "What does it mean if my %markerName% is %result%?",
  symptoms: "What symptoms could I have if my %markerName% is %result%?",
  actions: "What can I do if my %markerName% is %result%?",
  repeat: "When should I repeat my test?"
};

/**
 * Replaces placeholders in a template string with marker name and result.
 */

function replaceVariablesInTitle(template, markerName, result) {
  return template
    .replace("%markerName%", markerName)
    .replace("%result%", result);
}

/**
 * Matches all occurrences of a pattern in a given text.
 */
function matchAll(pattern, text) {
  const regex = new RegExp(pattern, "g");
  const matches = [];
  let matchResult;
  while ((matchResult = regex.exec(text))) {
    matches.push(matchResult);
  }
  return matches;
}

/**
 * Adds reference links to the text based on numeric references in parentheses.
 */
function addReferenceLinks(text) {
  const pattern = /\(([0-9,]+)\)/g;
  const items = matchAll(pattern, text);
  const matches = Array.from(items);

  if (!matches.length) return text;

  matches.forEach(matchedGroup => {
    if (!matchedGroup[1]) return;
    const references = matchedGroup[1].split(",");

    let referenceLinks = references
      .map(
        ref =>
          `<a href="https://myhealthchecked.com/references#ref-${ref}" target="_blank" rel="noopener noreferrer">${ref}</a>`
      )
      .join(",");

    text = text.replace(matchedGroup[0], `<sup>[${referenceLinks}]</sup>`);
  });

  return text;
}

/**
 * Prepares questions and answers based on the marker's details.
 */

function prepareQuestionsAndAnswers(marker) {
  const { details } = marker;
  const result = [];
  const keys = Object.keys(details);

  keys.forEach(key => {
    const answer = details[key];
    if (!answer) return;

    let question = MarkerDetailQuestions[key];

    if (key === "meaning" && marker.result === "normal") {
      question = "How can I maintain my normal result?";
    }

    if (marker.value.toString() === "**DETECTED**" && key === "what_can") {
      question = `What can I do if I've tested positive for ${marker.name}?`;
    }

    if (
      marker.value.toString().toLowerCase() === "unconfirmed reactive" &&
      key === "meaning"
    ) {
      question = `What does it mean if my result says unconfirmed reactive for ${marker.name}?`;
    }

    if (marker.type === "negative-positive" && key === "repeat") {
      question = "How can I maintain my normal results?";
    }

    let markerResult = marker.result;
    if (marker.type === "negative-positive") {
      markerResult = marker.result === "green" ? "not detected" : "detected";
    }

    result.push({
      question: replaceVariablesInTitle(question, marker.name, markerResult),
      answer: addReferenceLinks(answer)
    });
  });

  return result;
}

/**
 * Generates a badge indicating the status of the marker result.
 */

function generateBadge(marker) {
  let text = `Your result is ${marker.result}`;
  let status = marker.result;

  if (marker.value.toString().toLowerCase() === "unconfirmed reactive") {
    text = "Further testing required";
    status = "unconfirmed";
  } else if (marker.result === "amber") {
    text = "Medical attention required";
    status = "amber";
  } else if (marker.result === "green") {
    text = "Everything looks good";
    status = "green";
  }

  return (
    <Badge className={`markerModal__result markerModal__result--${status}`}>
      {text}
    </Badge>
  );
}

/**
 * BloodMarkerModal component
 * Displays a modal with detailed information about a blood marker, including
 * questions and answers related to the marker's results.
 */

export function BloodMarkerModal({ marker, isOpen, toggle }) {
  const questionAnswers = prepareQuestionsAndAnswers(marker);

  return (
    <Modal
      className={`markerModal__container ${[
        "normal",
        "green",
        "Not Detected"
      ].includes(marker.result) && "markerModal__container--normal"} ${[
        "unconfirmed reactive"
      ].includes(String(marker.value).toLowerCase()) &&
        "markerModal__container--unconfirmed"}`}
      show={isOpen}
      onHide={toggle}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="markerModal__title">{marker.name}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{generateBadge(marker)}</h3>
        <dl className="markerModal__questions-list">
          {questionAnswers.map(({ question, answer }) => (
            <React.Fragment key={question}>
              <dt className="markerModal__question">{question}</dt>
              <dd
                className="markerModal__answer"
                dangerouslySetInnerHTML={{ __html: answer }}
              ></dd>
            </React.Fragment>
          ))}
        </dl>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

BloodMarkerModal.propTypes = {
  marker: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};
