import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../stories/Buttons/Button";

/**
 * ShConsent component
 *
 * This component displays information about the appropriate testing windows for
 * sexually transmitted infections (STIs) after exposure. It provides guidance
 * on when to test and encourages users to seek help if necessary.
 *
 * The user can navigate to the next step by clicking the "Confirm & Continue" button.
 *
 * @returns {JSX.Element} The rendered consent information and navigation button.
 */

export default function ShConsent() {
  const { primaryBarcode } = useParams();
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(`/essential-tips/${primaryBarcode}`);
  };

  return (
    <div className="row justify-content-center pt-4 sh-consent-page">
      <div className="mt-3 p-0 col-10">
        <h1 className="mb-4">When should I test after exposure?</h1>

        <p>
          {" "}
          When testing for sexually transmitted infections (STIs), some
          infections do not show up immediately after sex. The testing window,
          or the period of time after exposure you will get an accurate result,
          can vary by infection type. For the most accurate test results, we
          recommend that you follow the testing windows listed below.
        </p>
        <p>
          {" "}
          <strong>Chlamydia</strong> - 2 weeks after exposure
        </p>
        <p>
          {" "}
          <strong>Gonorrhoea</strong> - 2 weeks after exposure
        </p>
        <p>
          {" "}
          <strong>Syphilis*</strong> - 4 weeks after exposure
        </p>
        <p>
          {" "}
          <strong>HIV*</strong> - 45 days after exposure. This applies to
          fourth-generation testing, which is the testing method used at our
          lab.
        </p>
        <p>
          {" "}
          If you've had high-risk exposure to either syphilis or HIV, we
          recommend seeking help as soon as possible by attending a local sexual
          health clinic or speaking to your GP. If you have been in contact with
          HIV, you may need post-exposure prophylaxis after sexual intercourse.
          Both tests should be repeated 12 weeks after your exposure.
        </p>

        <p>
          <span className="text-rusty-red">
            *If you are unsure about whether you are within a testing window,
            you should do a test now and another test in 12 weeks.
          </span>
        </p>
        <div className="d-flex justify-content-end">
          <Button label={"Confirm & Continue"} onClick={handleSubmit}></Button>
        </div>
      </div>
    </div>
  );
}
