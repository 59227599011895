import React from "react";
import { SplitScreen } from "./components/SplitScreen";
import Page from "./Page";
import SideBar from "./Page/SideBar";
import Toaster from "./stories/Toasts/Toaster";
import { useAuth } from "./components/AuthContext/AuthContext";
import { Container } from "react-bootstrap";

function App() {
  const auth = useAuth();

  return (
    <>
      <main>
        <SplitScreen isSidebar={true} rightWeight={4}>
          <SideBar />
          <Container fluid>
            <Page />
          </Container>
          <Toaster newToast={auth?.toast} />
        </SplitScreen>
      </main>
    </>
  );
}

export default App;
