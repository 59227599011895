import React from "react";
import zxcvbn from "zxcvbn";
import { ProgressBar } from "react-bootstrap";

// Defines password strength levels
const strengthLabels = ["Very weak", "Weak", "Medium", "Strong", "Very strong"];

const PasswordStrength = ({ password }) => {
  const testResult = zxcvbn(password);

  // Converts score to percentage
  const num = (testResult.score * 100) / 4;

  // Function to create password label based on score
  const createPasswordLabel = () => strengthLabels[testResult.score] || "Weak";

  const renderSuggestions = () => {
    if (testResult.feedback.suggestions.length > 0) {
      return (
        <div>
          {testResult.feedback.suggestions.map((suggestion, index) => (
            <small key={index} className="text-muted">
              {suggestion}
            </small>
          ))}
        </div>
      );
    }
    return null;
  };

  //Renders warning
  const renderWarning = () => {
    if (testResult.feedback.warning) {
      return (
        <small className="text-danger">{testResult.feedback.warning}</small>
      );
    }
    return null;
  };

  return (
    <div className="password-strength-meter">
      <div>Password Strength</div>
      <ProgressBar now={num} label={createPasswordLabel()} />
      {renderWarning()}
      {renderSuggestions()}
    </div>
  );
};

export default PasswordStrength;
