import React from 'react';
import { Col, Row } from 'react-bootstrap'; // Import from react-bootstrap

/**
 * Notice component
 *
 * This component is used to display a notice message with a title and content.
 * It accepts two props: `title` (a string for the title) and `children` (for any content that will be displayed inside the notice).
 * The component is structured using Bootstrap's grid system with `Row` and `Col` components for layout and styling.
 * @returns {JSX.Element} The rendered component for displaying the notice.
 */
const Notice = ({ title, children }) => (
  <Row className="justify-content-center mb-3">
    <Col md={6} className="text-center">
      <h1 className="pb-3">{title}</h1>
      <div className="text-muted">{children}</div>
    </Col>
  </Row>
);

export default Notice;
