import React from 'react';
import { Button } from '../../stories/Buttons/Button';
import { useParams } from 'react-router-dom';

const PhlebotomyStartAssessment = () => {
    const {test_type} = useParams();
    const {bid} = useParams();
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center pt-4 dashboard-page">
                    <div className="mt-3 p-0 col-10">
                        <div className="dashboard">
                            <div className="m-4 p-4">
                                <p>You will now need to complete a short health assessment as part of your test activation process</p>
                                <p>This will only take 2 minutes to complete.</p>
                                <Button
                                    href={`/bloods/boots/assessment-form/${test_type}/${bid}`}
                                    label= "Start health assessment"
                                    className="btn-start-asssessment"
                                >
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhlebotomyStartAssessment;
