import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

/**
 * Manages restricted access to the views
 * @param {Object} props - The input parameters.
 * @param {Element} props.element - The HTML element to be used.
 * @param {Object} props.rest - The rest of the parameters.
 */

let redirect_url;

const PrivateRoute = ({ element: Element, ...rest }) => {

  const { isAuthenticated } = useAuth();

  const dont_next = ["/", "/logout/"]; // Routes to exclude from redirection
  
  const location = useLocation();
  const auth = useAuth();

  // Handle routes that should not be redirected
  if (dont_next.includes(location.pathname)) {
    redirect_url = "/login";
  } else {
    // Set redirection based on the phlebotomy flag
    redirect_url = `/login/?next=${encodeURIComponent(location.pathname)}`;
    redirect_url = `/login/?next=${encodeURIComponent(location.pathname)}`;
  }

  // Set the next URL for redirect
  auth.setNextUrl(redirect_url.split("?next=")[1]?.replace(/%2F/g, '/'));
  auth.setNextUrl(redirect_url.split("?next=")[1]?.replace(/%2F/g, '/'));

  // If the user is authenticated, render the requested element, otherwise redirect
  return isAuthenticated ? (
    <Element {...rest} />
  ) : (
    <Navigate to={redirect_url ? redirect_url : "/login"} />
  );
};

export default PrivateRoute;