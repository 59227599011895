import goldImage from "../../assets/images/gold-tube-barcode-label.png";
import royalImage from "../../assets/images/royal-mail-tracking-number-label.png";
import { TEST_TYPES } from "../../utils";
import { formatSecondaryBarcode } from "../../utils";

export const loginFields = [
  {
    controlId: "1",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Password",
    type: "password",
    name: "password",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Log in",
    type: "submit",
    linkName: "Reset password",
    href: "/reset-password",
    size: "md",
    variant: "primary",
    className: "btn-login"
  }
];

export const resetPassword = [
  {
    controlId: "1",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "recaptcha",
    type: "recaptcha",
    required: true
  },
  {
    controlId: "2",
    label: "Reset Password",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-reset-password"
  }
];

export const createAccount = [
  {
    controlId: "1",
    label: "First Name",
    type: "text",
    name: "firstname",
    as: "",
    required: true,
    maxLength: 256,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Last Name",
    type: "text",
    name: "lastname",
    as: "",
    maxLength: 256,
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Email",
    type: "email",
    name: "email",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Password",
    type: "password",
    name: "password",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    linkName: "Reset Password",
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "5",
    label: "Marketing Consent",
    noLabel: true,
    type: "checkbox",
    name: "marketing_consent",
    helpText:
      "We would love to share our latest product innovation, expert health info, and offers with you. We’ll never share your personal data and you can unsubscribe at any time.",
    as: "",
    required: false,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group",
    helpTextClass: ""
  },
  {
    controlId: "recaptcha",
    type: "recaptcha",
    required: true
  },
  {
    controlId: "6",
    label: "Create account",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn m-2 rounded-pill h-25 float-end btn-create-account"
  }
];

export const bodyFields = [
  {
    controlId: "1",
    label: "Please enter your height (cm)",
    type: "number",
    name: "height",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Please enter your weight (kg)",
    type: "number",
    name: "weight",
    as: "",
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val2",
    className: "form-group"
  },
  {
    name: "units",
    controlId: "3",
    label: "Units",
    type: "toggle-buttons",
    required: true,
    convert: true,
    options: [
      { value: "Imperial", label: "Imperial" },
      { value: "Metric", label: "Metric" }
    ],
    className: "toggle-btn-group",
    aria_describedby: "val3"
  },
  {
    controlId: "4",
    label: "Do you smoke cigarettes?",
    type: "select",
    name: "smoking",
    as: "",
    break: true,
    h1: "Smoking and alcohol",
    required: true,
    options: ["Yes", "No"],
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val4",
    className: "form-group"
  },
  {
    controlId: "5",
    label: "How many cigarettes do you smoke each day?",
    type: "select",
    name: "cigarettes_per_day",
    as: "",
    hide: true,
    options: ["0 - 5", "6 - 10", "11 - 20", "20+"],

    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val5",
    className: "form-group"
  },
  {
    controlId: "6",
    label: "Do you drink alcohol?",
    type: "select",
    name: "alcohol",
    as: "",
    options: ["Yes", "No"],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val6",
    className: "form-group"
  },
  {
    controlId: "7",
    label: "How many units do you drink each week?",
    type: "select",
    name: "alcohol_units_per_week",
    options: ["0 - 7", "7 - 14", "15 - 30", "30+"],
    as: "",
    required: true,
    hide: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val7",
    className: "form-group"
  },
  {
    controlId: "8",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];

export const exerciseFields = [
  {
    controlId: "1",
    label: "Do you do any physical activity?",
    type: "select",
    name: "physical_activity",
    as: "",
    options: ["Yes", "No"],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Light activity, e.g. walking, stretching",
    type: "select",
    name: "light_activity_hours_per_week",
    as: "",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "7+"],
    required: true,
    hide: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Moderate activity, e.g. aerobics, golf",
    type: "select",
    name: "moderate_activity_hours_per_week",
    as: "",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "7+"],
    required: true,
    hide: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val3",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Vigorous activity, e.g. running, weight training",
    type: "select",
    name: "vigorous_activity_hours_per_week",
    as: "",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "7+"],
    required: true,
    hide: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val4",
    className: "form-group"
  },
  {
    controlId: "5",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];

export const checkboxGroupFormFields = [
  {
    controlId: "1",
    label: "Have you been diagnosed with any medical conditions?",
    type: "select",
    name: "was_diagnosed",
    options: ["Yes", "No"],
    required: true,
    aria_describedby: "val1",
    placeholder: "Please choose",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Please tick all that apply:",
    type: "group-label",
    required: true,
    hide: true,
    name: "medical_conditions_group",
    required: false,
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Anaemia",
    type: "checkbox",
    group_name: "medical_conditions_group",
    name: "Anaemia",
    value: "Anaemia",
    aria_describedby: "val3",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Cancer",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Cancer",
    value: "Cancer",
    aria_describedby: "val4",
    className: "form-group"
  },
  {
    controlId: "5",
    label: "Chronic Kidney Disease",
    type: "checkbox",
    name: "Chronic Kidney Disease",
    group_name: "medical_conditions_group",

    value: "Chronic Kidney Disease",
    aria_describedby: "val5",
    className: "form-group"
  },
  {
    controlId: "6",
    label: "Coeliac Disease",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Coeliac Disease",
    value: "Coeliac Disease",
    aria_describedby: "val6",
    className: "form-group"
  },
  {
    controlId: "7",
    label: "COPD",
    type: "checkbox",
    name: "COPD",
    group_name: "medical_conditions_group",

    value: "COPD",
    aria_describedby: "val7",
    className: "form-group"
  },
  {
    controlId: "8",
    label: "Diabetes",
    type: "checkbox",
    name: "Diabetes",
    value: "Diabetes",
    group_name: "medical_conditions_group",

    aria_describedby: "val8",
    className: "form-group"
  },
  {
    controlId: "9",
    label: "Dementia",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Dementia",
    value: "Dementia",
    aria_describedby: "val9",
    className: "form-group"
  },
  {
    controlId: "10",
    label: "Gout",
    group_name: "medical_conditions_group",

    type: "checkbox",
    name: "Gout",
    value: "Gout",
    aria_describedby: "val10",
    className: "form-group"
  },
  {
    controlId: "11",
    label: "Heart disease",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Heart disease",
    value: "Heart disease",
    aria_describedby: "val11",
    className: "form-group"
  },
  {
    controlId: "12",
    label: "High blood pressure",
    type: "checkbox",
    name: "High blood pressure",
    group_name: "medical_conditions_group",

    value: "High blood pressure",
    aria_describedby: "val12",
    className: "form-group"
  },
  {
    controlId: "13",
    label: "High cholesterol",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "High cholesterol",
    value: "High cholesterol",
    aria_describedby: "val13",
    className: "form-group"
  },
  {
    controlId: "14",
    label: "HIV",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "HIV",
    value: "HIV",
    aria_describedby: "val14",
    className: "form-group"
  },
  {
    controlId: "15",
    label: "Irritable bowel syndrome",
    group_name: "medical_conditions_group",

    type: "checkbox",
    name: "Irritable bowel syndrome",
    value: "Irritable bowel syndrome",
    aria_describedby: "val15",
    className: "form-group"
  },
  {
    controlId: "16",
    label: "Inflammatory bowel disease",
    type: "checkbox",
    name: "Inflammatory bowel disease",
    value: "Inflammatory bowel disease",
    aria_describedby: "val16",
    className: "form-group"
  },
  {
    controlId: "17",
    label: "Irregular heart rhythm",
    group_name: "medical_conditions_group",

    type: "checkbox",
    name: "Irregular heart rhythm",
    value: "Irregular heart rhythm",
    aria_describedby: "val17",
    className: "form-group"
  },
  {
    controlId: "18",
    label: "Liver disease",
    type: "checkbox",
    name: "Liver disease",
    group_name: "medical_conditions_group",

    value: "Liver disease",
    aria_describedby: "val18",
    className: "form-group"
  },
  {
    controlId: "19",
    label: "Mental health problems",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Mental health problems",
    value: "Mental health problems",
    aria_describedby: "val19",
    className: "form-group"
  },
  {
    controlId: "20",
    label: "Polycystic Ovary Syndrome",
    group_name: "medical_conditions_group",

    type: "checkbox",
    name: "Polycystic Ovary Syndrome",
    value: "Polycystic Ovary Syndrome",
    aria_describedby: "val20",
    className: "form-group"
  },
  {
    controlId: "21",
    label: "Rheumatoid arthritis",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Rheumatoid arthritis",
    value: "Rheumatoid arthritis",
    aria_describedby: "val21",
    className: "form-group"
  },
  {
    controlId: "22",
    label: "Thyroid problems",
    group_name: "medical_conditions_group",

    type: "checkbox",
    name: "Thyroid problems",
    value: "Thyroid problems",
    aria_describedby: "val22",
    className: "form-group"
  },
  {
    controlId: "23",
    label: "Stroke / TIA",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "Stroke / TIA",
    value: "Stroke / TIA",
    aria_describedby: "val23",
    className: "form-group"
  },
  {
    controlId: "24",
    label: "Other",
    type: "checkbox",
    group_name: "medical_conditions_group",

    name: "other_description",
    value: "Other",
    aria_describedby: "val24",
    className: "form-group"
  },
  {
    controlId: "25",
    label: "Please specify in the box below",
    required: false,
    placeholder: "Please specify conditions here",
    as: "textarea",
    name: "other_description",
    aria_describedby: "val25",
    className: "form-group"
  },
  {
    controlId: "26",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];

export const medicationFields = [
  {
    controlId: "1",
    label: "Blood pressure medication",
    type: "checkbox",
    group_name: "medications_group",
    name: "Blood pressure medication",
    value: "Blood pressure medication",
    required: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Cholesterol lowering drugs",
    type: "checkbox",
    name: "Cholesterol lowering drugs",
    group_name: "medications_group",
    value: "Cholesterol lowering drugs",
    required: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Contraceptives",
    type: "checkbox",
    name: "Contraceptives",
    group_name: "medications_group",
    value: "Contraceptives",
    required: false,
    aria_describedby: "val3",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Diabetes medication including insulin",
    type: "checkbox",
    group_name: "medications_group",
    name: "Diabetes medication including insulin",
    value: "Diabetes medication including insulin",
    required: false,
    aria_describedby: "val4",
    className: "form-group"
  },
  {
    controlId: "5",
    label: "Diuretics",
    type: "checkbox",
    group_name: "medications_group",
    name: "Diuretics",
    value: "Diuretics",
    required: false,
    aria_describedby: "val5",
    className: "form-group"
  },
  {
    controlId: "6",
    label: "Heart medication",
    type: "checkbox",
    group_name: "medications_group",
    name: "Heart medication",
    value: "Heart medication",
    required: false,
    aria_describedby: "val6",
    className: "form-group"
  },
  {
    controlId: "7",
    label: "Mental health medication",
    type: "checkbox",
    group_name: "medications_group",
    name: "Mental health medication",
    value: "Mental health medication",
    required: false,
    aria_describedby: "val7",
    className: "form-group"
  },
  {
    controlId: "8",
    label: "Steroids",
    type: "checkbox",
    group_name: "medications_group",
    name: "Steroids",
    value: "Steroids",
    required: false,
    aria_describedby: "val8",
    className: "form-group"
  },
  {
    controlId: "9",
    label: "Thyroxine",
    group_name: "medications_group",
    type: "checkbox",
    name: "Thyroxine",
    value: "Thyroxine",
    required: false,
    aria_describedby: "val9",
    className: "form-group"
  },
  {
    controlId: "10",
    label: "Other",
    type: "checkbox",
    name: "other_description",
    value: "Other",
    group_name: "medications_group",
    required: false,
    aria_describedby: "val10",
    className: "form-group"
  },
  {
    controlId: "11",
    label: "Please specify in box below",
    as: "textarea",
    name: "other_description",
    placeholder: "Please specify medications here", // empty initial value for textarea
    required: true,
    aria_describedby: "val11",
    className: "form-group"
  },
  {
    controlId: "12",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];

export const menstrualCycleFields = [
  {
    controlId: "1",
    label: "What is your normal cycle length?",
    type: "select",
    name: "cycle_length",
    as: "",
    required: true,
    options: [
      "Around 28 days",
      "29-40",
      "It’s not regular",
      "Menopausal",
      "Pregnant"
    ],
    placeholder: "",
    size: "text",
    readOnly: true,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label:
      "Which day of your cycle was this blood test taken on? Your cycle begins on the 1st day of your period.Please choose",
    type: "select",
    name: "day_of_cycle",
    as: "",
    options: [
      "1 - 5",
      "6 - 14",
      "15 - 21",
      "22-28",
      "29-40",
      "40+",
      "Not sure",
      "Menopausal",
      "Pregnant"
    ],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Are you taking contraception?",
    type: "select",
    name: "contraception",
    as: "",
    options: [
      "No",
      "Combined pill",
      "Mini pill",
      "Contraceptive implant",
      "Contraceptive injection",
      "IUS or hormonal coil",
      "IUD or copper coil",
      "Other"
    ],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,

    aria_describedby: "val3",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];
export const sexualHealthFields = [
  {
    controlId: "1",
    label: "Are you sexually active?",
    type: "select",
    name: "sexually_active",
    options: ["Yes", "No"],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val1",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Your sexual partners are:",
    type: "select",
    name: "partners",
    options: ["Men", "Women", "Both"],
    required: true,
    placeholder: "",
    size: "text",
    hide: true,
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "How long ago did you last have sex?",
    type: "select",
    name: "recent_sex",
    hide: true,
    options: [
      "Less than a week ago",
      "In the past month",
      " 2-3 months ago",
      " More than 3 months ago"
    ],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val3",
    className: "form-group"
  },
  {
    controlId: "4",
    label: "Have you had unprotected sex in the past 3 months?",
    type: "select",
    name: "had_unprotected_sex",
    options: ["Yes", "No"],
    required: true,
    hide: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val4",
    className: "form-group"
  },
  // {
  //   controlId: "3",
  //   label: "Which type of sex was unprotected?",
  //   type: "select",
  //   name: "unprotected_sex_type",
  //   options: ["Oral sex",
  //     "Vaginal sex",
  //     " Anal sex"],
  //     hide:true,
  //   required: true,
  //   placeholder: "",
  //   size: "text",
  //   readOnly: false,
  //   disabled: false,
  //   aria_describedby: "val3",
  //   className: "form-group"
  // },
  {
    check_group_label: "Which type of sex was unprotected?",
    name: "unprotected_sex_type",
    check_group_required: true,
    check_group_hide: true,
    check_box_group: [
      {
        controlId: "18",
        label: "Oral sex",
        type: "checkbox",
        noLabel: true,
        name: "Oral sex",
        group_name: "unprotected_sex_type",
        value: "Oral sex",
        required: false,
        aria_describedby: "val18",
        className: "form-group"
      },
      {
        controlId: "19",
        label: "Vaginal sex",
        type: "checkbox",
        name: "Vaginal sex",
        group_name: "unprotected_sex_type",
        value: "Vaginal sex",
        required: false,
        aria_describedby: "val19",
        className: "form-group"
      },
      {
        controlId: "20",
        label: "Anal sex",
        type: "checkbox",
        name: "unprotected_sex_type",
        group_name: "unprotected_sex_type",
        value: "Anal sex",
        required: false,
        aria_describedby: "val20",
        className: "form-group"
      }
    ]
  },
  {
    controlId: "5",
    label: "Have you ever been diagnosed with an STI?",
    type: "select",
    name: "sti_diagnosed",
    options: [
      "No",
      "Yes, currently diagnosed",
      "Yes, previously diagnosed but fine now"
    ],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val5",
    className: "form-group"
  },
  {
    controlId: "6",
    label: "Do you have any symptoms?",
    type: "select",
    name: "sti_symptoms",
    options: ["Yes", "No"],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val6",
    className: "form-group"
  },
  {
    controlId: "7",
    label: "Please specify",
    as: "textarea",
    type: "",
    name: "sti_symptoms_description",
    hide: true,
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val7",
    className: "form-group"
  },
  {
    controlId: "8",
    label: "Do you think you may have been exposed to an STI?",
    type: "select",
    name: "potential_sti_exposure",
    options: ["Yes", "No"],
    required: true,
    placeholder: "",
    size: "text",
    readOnly: false,
    disabled: false,
    aria_describedby: "val8",
    className: "form-group"
  },
  {
    check_group_label: "Please specify",
    name: "potential_sti_exposure",
    check_group_required: true,
    check_group_hide: true,
    check_box_group: [
      {
        controlId: "9",
        label: "Chlamydia",
        type: "checkbox",
        noLabel: true,
        name: "Chlamydia",
        group_name: "potential_sti_exposure",
        value: "Chlamydia",
        required: false,
        aria_describedby: "val9",
        className: "form-group"
      },
      {
        controlId: "10",
        label: "Gonorrhoea",
        type: "checkbox",
        name: "Gonorrhoea",
        group_name: "potential_sti_exposure",
        value: "Gonorrhoea",
        required: false,
        aria_describedby: "val10",
        className: "form-group"
      },
      {
        controlId: "11",
        label: "Herpes",
        type: "checkbox",
        name: "Herpes",
        group_name: "potential_sti_exposure",
        value: "Herpes",
        required: false,
        aria_describedby: "val11",
        className: "form-group"
      },
      {
        controlId: "12",
        label: "HIV",
        type: "checkbox",
        name: "HIV",
        group_name: "potential_sti_exposure",
        value: "HIV",
        required: false,
        aria_describedby: "val12",
        className: "form-group"
      },
      {
        controlId: "13",
        label: "HPV",
        type: "checkbox",
        name: "HPV",
        group_name: "potential_sti_exposure",
        value: "HPV",
        required: false,
        aria_describedby: "val13",
        className: "form-group"
      },
      {
        controlId: "14",
        label: "Syphilis",
        type: "checkbox",
        name: "Syphilis",
        group_name: "potential_sti_exposure",
        value: "Syphilis",
        required: false,
        aria_describedby: "val14",
        className: "form-group"
      },
      {
        controlId: "15",
        label: "Other",
        group_name: "potential_sti_exposure",
        type: "checkbox",
        name: "Other",
        value: "Other",
        required: false,
        aria_describedby: "val15",
        className: "form-group"
      }
    ]
  },
  {
    controlId: "16",
    label: "Confirm and Continue",
    type: "submit",
    size: "md",
    variant: "primary",
    className: "btn-login mt-2"
  }
];

export const setPasswordFields = [
  {
    controlId: "1",
    label: "New Password",
    type: "password",
    name: "password",
    as: "",
    required: true,
    placeholder: "Enter you password",
    size: "large",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "2",
    label: "Confirm Password",
    type: "password",
    name: "confirmpassword",
    as: "",
    required: true,
    placeholder: "Confirm your password",
    size: "large",
    readOnly: false,
    disabled: false,
    aria_describedby: "val2",
    className: "form-group"
  },
  {
    controlId: "3",
    label: "Reset Password",
    type: "submit",
    size: "large",
    variant: "primary",
    className: "btn m-2"
  }
];

export const secondaryBarcodeFields = (primaryBarcode) => [
  {
    name: "secondary_barcode",
    label: "Secondary barcode",
    type: "text",
    value:
      formatSecondaryBarcode(localStorage.getItem("secondary_barcode_id")) ||
      "Secondary barcode not found",
    readOnly: true,
    disabled: false,
  },
  {
    controlId: "confirmButton",
    type: "button-group",
    buttons: [
      {
        label: "No it's not correct",
        type: "submit",
        size: "lg",
        className: "secondary-barcode-buttons",
        variant: "grey",
        href: "https://myhealthchecked.com/when-you-ll-get-your-test-results",
      },
      {
        label: "Confirm & continue",
        type: "submit",
        size: "lg",
        className: "secondary-barcode-buttons",
        variant: "primary",
        href: `/consent/${primaryBarcode}`,
      },
    ],
    className: "barcode-2-btn-group",
  },
];

const today = new Date();
const defaultDateFor18YearsOld = new Date(
  today.setFullYear(today.getFullYear() - 18)
);

// Format the date to `YYYY-MM-DD` for the input value
const formattedDefaultDate = defaultDateFor18YearsOld
  .toISOString()
  .split("T")[0];

export const confirmDetailsFields = [
  {
    controlId: "firstname",
    label: "First name",
    type: "text",
    name: "first_name",
    placeholder: "",
    required: true,
    className: "form-group",
    maxLength: 256,
  },
  {
    controlId: "lastname",
    label: "Last name",
    type: "text",
    maxLength: 256,
    placeholder: "",
    name: "last_name",
    required: true,
    className: "form-group",
    maxLength: 256
  },
  {
    controlId: "number",
    label: "Main phone number",
    type: "tel",
    className: "form-group",
    name: "phone_number_primary",
    required: true,
    helpText:
      "At MyHealthChecked, we require a phone number for patients who purchase sexual health tests. This is to ensure our doctors can reach you with your results and any necessary follow-up care in a timely and confidential manner. Please note that providing your phone number does not indicate a positive result. Patient privacy and safeguarding are top priorities for us, and we handle your information with the utmost care."
  },
  {
    controlId: "dob",
    label: "Date of birth",
    type: "date",
    className: "form-group",
    name: "dob",
    maxLength: formattedDefaultDate,
    required: true,
    helpText:
      "Please note that our tests are designed for individuals who are 18 years of age or older. We kindly request that you provide a date of birth indicating that you are at least 18 years old."
  },
  {
    name: "sex",
    controlId: "gender",
    label: "Gender at birth",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" }
    ],
    helpText:
      "We ask this to provide the optimal results based on your gender.",
    className: "toggle-btn-group"
  },
  {
    name: "identify_with_gender",
    controlId: "confirmation",
    label: "Do you still identify with this gender?",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" }
    ],
    helpText: "Your response helps us customize the process.",
    className: "toggle-btn-group"
  },
  {
    controlId: "ethnicity",
    label: "Ethnicity",
    type: "radio",
    name: "ethnicity",
    required: true,
    choices: [
      { value: "white", label: "White" },
      { value: "asian", label: "Asian" },
      { value: "black", label: "Black, African, Caribbean or Black British" },
      { value: "mixed", label: "Mixed ethnic group" },
      { value: "other", label: "Other ethnic group" }
    ],
    className: "form-group"
  },
  {
    controlId: "confirmButton",
    label: "Continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const confirmDetailsFieldsSh = [
  {
    controlId: "firstname",
    label: "First name",
    type: "text",
    maxLength: 256,
    name: "first_name",
    placeholder: "",
    required: true,
    className: "form-group",
    maxLength: 256
  },
  {
    controlId: "lastname",
    label: "Last Name",
    maxLength: 256,
    type: "text",
    placeholder: "",
    name: "last_name",
    required: true,
    className: "form-group",
    maxLength: 256
  },
  {
    controlId: "number",
    label: "Main phone number",
    type: "tel",
    className: "form-group",
    name: "phone_number_primary",
    required: true,
    helpText:
      "At MyHealthChecked, we require a phone number for patients who purchase sexual health tests. This is to ensure our doctors can reach you with your results and any necessary follow-up care in a timely and confidential manner. Please note that providing your phone number does not indicate a positive result. Patient privacy and safeguarding are top priorities for us, and we handle your information with the utmost care."
  },
  {
    controlId: "dob",
    label: "Date of birth",
    type: "date",
    className: "form-group",
    name: "dob",
    required: true,
    helpText:
      "Please note that our tests are designed for individuals who are 18 years of age or older. We kindly request that you provide a date of birth indicating that you are at least 18 years old."
  },
  {
    name: "sex",
    controlId: "gender",
    label: "Gender at birth",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" }
    ],
    helpText:
      "We ask this to provide the optimal results based on your gender.",
    className: "toggle-btn-group"
  },
  {
    name: "identify_with_gender",
    controlId: "confirmation",
    label: "Do you still identify with this gender?",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" }
    ],
    helpText: "Your response helps us customize the process.",
    className: "toggle-btn-group"
  },
  {
    controlId: "ethnicity",
    label: "Ethnicity",
    type: "radio",
    name: "ethnicity",
    required: true,
    choices: [
      { value: "white", label: "White" },
      { value: "asian", label: "Asian" },
      { value: "black", label: "Black, African, Caribbean or Black British" },
      { value: "mixed", label: "Mixed ethnic group" },
      { value: "other", label: "Other ethnic group" }
    ],
    className: "form-group"
  },
  {
    controlId: "confirmButton",
    label: "Continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const bloodFields = [
  {
    controlId: "barcode",
    label: "Gold barcode (required)",
    type: "text",
    name: "code",
    required: true,
    image: goldImage,
    className: "form-group",
    helpText: "10 character code printed on the sticker.",
    placeholder: "ABC-abc-1234"
  },
  {
    controlId: "trackingNumber",
    label: "Royal mail tracking number (required)",
    type: "text",
    name: "trackingNumber",
    required: true,
    image: royalImage,
    imgClassname: "mt-2 tracking-img",
    className: "form-group",
    helpText:
      "This number can be found on the royal mail return label included in your kit.",
    placeholder: "AA 1234 5678 9BB"
  },
  {
    controlId: "confirmButton",
    label: "Confirm & continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const shFields = [
  {
    controlId: "barcode",
    label: "Gold barcode (required)",
    type: "text",
    name: "code",
    placeholder: "ABC-abc-1234",
    required: true,
    image: goldImage,
    className: "form-group",
    helpText: "10 character code printed on the sticker."
  },
  {
    controlId: "trackingNumber",
    label: "Royal mail tracking number (required)",
    type: "text",
    name: "trackingNumber",
    placeholder: "AA 1234 5678 9BB",
    required: true,
    image: royalImage,
    className: "form-group",
    helpText:
      "This number can be found on the royal mail return label included in your kit."
  },
  {
    controlId: "confirmButton",
    label: "Confirm & continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];
export const dnaFields = [
  {
    controlId: "barcode",
    label: "DNA barcode (required)",
    type: "text",
    name: "code",
    placeholder: "CONCA12345",
    required: true,
    className: "form-group",
    helpText: "10 character code printed on the small sticker."
  },
  {
    controlId: "trackingNumber",
    label: "Royal mail tracking number (required)",
    type: "text",
    name: "trackingNumber",
    placeholder: "AA 1234 5678 9BB",
    required: true,
    image: royalImage,
    className: "form-group",
    helpText:
      "This number can be found on the royal mail return label included in your kit."
  },
  {
    controlId: "confirmButton",
    label: "Confirm & continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const confirmDetailsFieldsDna = [
  {
    controlId: "firstname",
    label: "First Name",
    maxLength: 256,
    type: "text",
    name: "first_name",
    placeholder: "",
    required: true,
    className: "form-group",
    maxLength: 256
  },
  {
    controlId: "lastname",
    label: "Last name",
    type: "text",
    maxLength: 256,
    placeholder: "",
    name: "last_name",
    required: true,
    className: "form-group",
    maxLength: 256
  },
  {
    controlId: "number",
    label: "Main phone number",
    type: "tel",
    className: "form-group",
    name: "phone_number_primary",
    required: true,
    helpText:
      "At MyHealthChecked, we require a phone number for patients who purchase sexual health tests. This is to ensure our doctors can reach you with your results and any necessary follow-up care in a timely and confidential manner. Please note that providing your phone number does not indicate a positive result. Patient privacy and safeguarding are top priorities for us, and we handle your information with the utmost care."
  },
  {
    controlId: "dob",
    label: "Date of birth",
    type: "date",
    className: "form-group",
    name: "dob",
    required: true,
    helpText:
      "Please note that our tests are designed for individuals who are 18 years of age or older. We kindly request that you provide a date of birth indicating that you are at least 18 years old."
  },
  {
    name: "sex",
    controlId: "gender",
    label: "Gender at birth",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" },
      { value: "pnts", label: "Prefer not to say" }
    ],
    helpText:
      "We ask this to provide the optimal results based on your gender.",
    className: "toggle-btn-group"
  },
  {
    controlId: "ethnicity",
    label: "Ethnicity",
    type: "radio",
    name: "ethnicity",
    required: true,
    choices: [
      { value: "white", label: "White" },
      { value: "asian", label: "Asian" },
      { value: "black", label: "Black, African, Caribbean or Black British" },
      { value: "mixed", label: "Mixed ethnic group" },
      { value: "other", label: "Other ethnic group" }
    ],
    className: "form-group"
  },
  {
    controlId: "confirmButton",
    label: "Continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const timeAdministeredFields = [
  {
    label: "Date & time of test taken",
    type: "date",
    name: "sample_collection_date",
    placeholder: "DD/MM/YYYY",
    readOnly: false,
    required: true,
    maxLengthLength: 8,
    className: "pe-0 date-col",
    colSize: 6
  },
  {
    label: "",
    type: "time",
    name: "sample_collection_time",
    placeholder: "00:00",
    readOnly: false,
    required: false,
    className: "ps-0 time-col",
    colSize: 6
  },
  {
    controlId: "combinedHelpText",
    helpText:
      "Please enter the date and time that you performed your test; or the time you intend to take it within the next 60 minutes",
    type: "help-text"
  },
  {
    controlId: "confirmButton",
    label: "Confirm & continue",
    type: "submit",
    size: "lg",
    className: "confirm-button mt-3",
    variant: "primary"
  }
];

//Account fields definition
export const accountFields = [
  {
    controlId: "first_name",
    name: "first_name",
    label: "First name",
    maxLength:256,
    type: "text",
    placeholder: "Enter your first name",
    required: true
  },
  {
    controlId: "last_name",
    name: "last_name",
    label: "Last name",
    maxLength:256,
    type: "text",
    placeholder: "Enter your last name",
    required: true
  },
  {
    controlId: "email",
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "Enter your email",
    required: true,
    disabled: true 

  },
  {
    controlId: "dob",
    name: "dob",
    label: "Date of birth",
    type: "date",
    placeholder: "Enter your date of birth",
    max : formattedDefaultDate
  },
  {
    controlId: "phone_number_primary",
    name: "phone_number_primary",
    label: "Main phone number",
    type: "phone",
    maxLength_length: 30,
    placeholder: "Enter a valid UK phone number"
  },
  {
    controlId: "phone_number_secondary",
    name: "phone_number_secondary",
    label: "Alternative phone number",
    type: "tel",
    maxLength_length: 30
  },
  {
    name: "sex",
    controlId: "gender",
    label: "Gender at birth",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" },
      { value: "pnts", label: "Prefer not to say" }
    ],
    helpText:
      "Required for public health england reporting.",
    className: "toggle-btn-group"
  },
  {
    name: "identify_with_gender",
    controlId: "confirmation",
    label: "Do you still identify with this gender?",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" }
    ],
    helpText: "We ask of this so that we can provide the optimal results.",
    className: "toggle-btn-group"
  },
  {
    controlId: "ethnicity",
    name: "ethnicity",
    label: "Ethnicity",
    type: "radio",
    choices: [
      { value: "white", label: "White" },
      { value: "asian", label: "Asian" },
      { value: "black", label: "Black, African, Caribbean or Black British" },
      { value: "mixed", label: "Mixed ethnic group" },
      { value: "other", label: "Other ethnic group" }
    ]
  },
  {
    controlId: "home_street_address",
    name: "home_street_address",
    label: "Home street address",
    min_length: 1,
    maxLength_length: 127
  },
  {
    controlId: "home_city",
    name: "home_city",
    label: "Home city",
    max_length: 30
  },
  {
    controlId: "home_postal_code",
    name: "home_postal_code",
    label: "Home postal code",
    min_length: 1,
    maxLength_length: 127
  },
  {
    controlId: "home_country",
    name: "home_country",
    label: "Home country",
    fmt: "as-country",
    type: "countries",
  },
  {
    controlId: "passport_number",
    name: "passport_number",
    label: "Passport number",
    fmt: "inline-code",
    autocomplete: "off",
    min_length: 5,
    maxLength_length: 15
  },
  {
    controlId: "marketing_consent",
    name: "marketing_consent",
    label: "Marketing consent",
    type: "checkbox",
    helpText:
      "Tick this box to receive emails with news and offers from MyHealthChecked. We won't share your personal data and you can unsubscribe at any time."
  },
  {
    controlId: "cancelButton",
    name: "cancelButton",
    label: "Cancel",
    type: "cancel",
    variant: "secondary",
    visibleInDisplay: false
  },
  {
    controlId: "saveButton",
    name: "saveButton",
    label: "Save",
    type: "submit",
    variant: "primary",
    visibleInDisplay: false
  }
];

export const testTypeFields = [
  {
      controlId: "test_type",
      name: "test_type",
      type : "select",
      label: "If the test displayed does not match your selection, you can change it using the drop-down menu.",
      required: true,
      className: "form-group",
      helpText: "Please note that you will be required to pay for the test you selected on the day of your appointment.",
      options: [
        {value: TEST_TYPES["blood-cholesterol"], label: "Cholesterol Profile"},
        {value: TEST_TYPES["blood-energy"], label: "Energy Profile"},
        {value: TEST_TYPES["blood-general"], label: "General Health"},
        {value: TEST_TYPES["blood-heart"], label: "Heart Profile"},
        {value: "blood-thyroid", label: "Thyroid Profile"},
        {value: TEST_TYPES["blood-vitamins"], label: "Vitamins & Minerals Profile"},
        {value: TEST_TYPES["blood-weight"], label: "Weight Management"},
      ],
  },
  {
    controlId: "confirmButton",
    label: "Confirm & Continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];

export const phlebotomyConfirmDetailFields = [
  {
    controlId: "firstname",
    label: "First Name",
    type: "text",
    name: "first_name",
    placeholder: "",
    required: true,
    className: "form-group"
  },
  {
    controlId: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "",
    name: "last_name",
    required: true,
    className: "form-group"
  },
  {
    controlId: "dob",
    label: "Date of Birth",
    type: "date",
    className: "form-group",
    name: "dob",
    max: formattedDefaultDate,
    required: true,
    helpText:
      "Please note that our tests are designed for individuals who are 18 years of age or older. We apologise for any inconvenience caused, but we kindly request that you provide a date of birth indicating that you are at least 18 years old in order to proceed with the testing process."
  },
  {
    controlId: "number",
    label: "Main Phone Number",
    type: "tel",
    className: "form-group",
    name: "phone_number_primary",
    required: true,
    helpText:
      "We ask for this as on rare occasions, our doctors may need to reach out to you."
  },
  {
    name: "sex",
    controlId: "gender",
    label: "Sex at Birth",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" }
    ],
    helpText:
      "We ask of this so that we can provide the optimal results",
    className: "toggle-btn-group"
  },
  {
    name: "identify_with_gender",
    controlId: "confirmation",
    label: "Do you still identify with this gender?",
    type: "toggle-buttons",
    required: true,
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" }
    ],
    helpText: "We ask of this so that we can provide the optimal results",
    className: "toggle-btn-group"
  },
  {
    controlId: "confirmButton",
    label: "Continue",
    type: "submit",
    size: "lg",
    className: "confirm-button",
    variant: "primary"
  }
];