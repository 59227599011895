import React from "react";
import "../../../assets/scss/_horizontal-section.scss";
import NavItem from "./HorizontalSection/NavItem";
import { getStatusIcon } from "../../../utils/reportUtils";

/**
 * HorizontalSection component for displaying a navigable section with items.
 * @returns {JSX.Element} The rendered horizontal section.
 */

const HorizontalSection = ({ items, current, setCurrentItem }) => {
  /**
   * Renders the content of the current item based on the selection.
   * @returns {JSX.Element} The content of the currently selected item.
   */
  const renderContent = () => {
    const currentItem = items.find(i => i.name === current) || items[0];
    return currentItem.content();
  };

  return (
    <div className="horizontal-section-container">
      <aside className="horizontal-section-nav">
        {items
          .filter(item => !item.hiddenFromNav)
          .map(item => (
            <NavItem
              key={item.name}
              item={item}
              isCurrent={current === item.name}
              setCurrentItem={setCurrentItem}
              getStatusIcon={getStatusIcon}
            />
          ))}
      </aside>
      <main className="horizontal-section-main">{renderContent()}</main>
    </div>
  );
};

export default HorizontalSection;
