import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import DynamicForm from "../../stories/Forms/DynamicForm";

/**
 * WellnessCollectSample component
 *
 * This component facilitates the collection of a sample for wellness testing.
 * It presents the user with consent steps that must be acknowledged before
 * proceeding. It validates user input and submits the data to the server,
 * navigating to the next step upon success.
 *
 * @returns {JSX.Element} The rendered component for collecting a wellness sample.
 */

export default function WellnessCollectSample() {
  const { primaryBarcode } = useParams();
  const navigate = useNavigate();
  const postApiUrl = "api/dashboard/test-kits/sample-collection/";
  const { callApi, isLoading, setShowError, resultPost } = useApis();
  const id = localStorage.getItem("user_id");
  const barcode = localStorage.getItem("primary_barcode_id");
  const test_kit_id = localStorage.getItem("test_kit_id");
  const [warning, setShowWarning] = useState(false);
  const [checked, setChecked] = useState({
    one: false,
    two: false,
    three: false,
    four: false
  });

  const handleChange = prop => event => {
    setChecked(prevState => ({
      ...prevState,
      [prop]: event.target.checked
    }));
  };

  useEffect(() => {
    if (resultPost) {
      navigate(`/time-administered/${primaryBarcode}`);
    } else {
      setShowError(true);
    }
  }, [resultPost]);

  const handleSubmit = async e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!checked.one || !checked.two || !checked.three || !checked.four) {
      setShowWarning(true);
      return;
    }

    const formData = {
      barcode,
      consent_steps: {
        step1: checked.one,
        step2: checked.two,
        step3: checked.three,
        step4: checked.four
      },
      customer_id: id,
      test_kit_id: test_kit_id
    };

    await callApi(formData, postApiUrl, "POST");
  };

  const fields = [
    {
      controlId: "consent_step1",
      name: "consent_steps",
      type: "checkbox",
      heading: "I have left myself enough time to perform this test.",
      message: "Please note it can take up to 20 minutes from start to finish.",
      checked: checked.one,
      onChange: handleChange("one")
    },
    {
      controlId: "consent_step2",
      name: "consent_steps",
      type: "checkbox",
      heading: "I have not eaten or drunk anything for the past hour.",
      message:
        "Traces of foods or drink (including tea and coffee) might impair the quality and purity of your sample. Collect your DNA sample at least 1 hour after eating or drinking.",
      checked: checked.two,
      onChange: handleChange("two")
    },
    {
      controlId: "consent_step3",
      name: "consent_steps",
      type: "checkbox",
      heading: "I have not used mouthwash for the past hour.",
      message:
        "The use of mouthwash or toothpaste prior to sample collection can also impair your DNA quality. Please avoid using any non-foodstuffs orally (including brushing your teeth) for 1 hour prior to sample collection.",
      checked: checked.three,
      onChange: handleChange("three")
    },
    {
      controlId: "consent_step4",
      name: "consent_steps",
      type: "checkbox",
      heading: "I have rinsed my mouth before performing this test.",
      message:
        "For best results, rinse your mouth with water immediately prior to sampling.",
      checked: checked.four,
      onChange: handleChange("four")
    },
    {
      controlId: "youtube_video",
      type: "iframe",
      embedId: "oejVyz0G71o"
    },
    {
      controlId: "submit",
      type: "submit",
      className: "btn-cog mb-1 float-right",
      label: isLoading ? "Processing..." : "Confirm & continue",
      variant: "primary",
      disabled: isLoading
    }
  ];

  return (
    <div className="row justify-content-center py-5 wellness-collect-page">
      <div className="col-10">
        <h1 className="mb-4">Ready to collect your sample?</h1>
        <h2 className="text-left text-md-left">Before you begin</h2>
        <span className="text-muted">
          To get comfortable with the sample collection process, please read the
          'Instructions for use' leaflet that’s included with your kit and also
          watch the video provided below. Even if you have collected a sample
          before, watching the video can help you feel more confident about the
          process.
        </span>
        <div className="mt-4 mb-4">
          <DynamicForm
            fields={fields}
            className={"barcode-form"}
            onSubmit={handleSubmit}
          />
        </div>
        {warning && (
          <p className="text-danger">
            These steps are important ways to prepare for sample collection. If
            you cannot for any reason agree to these statements, please contact{" "}
            <strong>customercare@myhealthchecked.com</strong>.
          </p>
        )}
      </div>
    </div>
  );
}
