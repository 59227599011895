import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import _45MinCircle from "../../assets/icons/Platform/45 mins circle.png";
import drinkWaterCircle from "../../assets/icons/Platform/drink water circle.png";
import collectSampleGT from "../../assets/icons/Platform/Frame 4699.png";
import newFingerLT from "../../assets/icons/Platform/Frame 4701.png";
import sitcomfortably from "../../assets/icons/Platform/Frame 4702.png";
import goldTubeCircle from "../../assets/icons/Platform/Gold tube circle.png";
import milkingFingerCircle from "../../assets/icons/Platform/milking finger circle.png";
import newLancetCircle from "../../assets/icons/Platform/new lancet circle.png";
import puncturePositionCircle from "../../assets/icons/Platform/Puncture position circle.png";
import washHandscircle from "../../assets/icons/Platform/Wash hands circle.png";
import wipeFingerCircle from "../../assets/icons/Platform/wipe finger circle.png";
import exerciseCircle from "../../assets/icons/Platform/Exercise circle.png";
import { Button } from "../../stories/Buttons/Button";
import useApis from "../../services/useAPI";
import { useNavigate, useParams } from "react-router-dom";

/**
 * EssenTialTips component
 *
 * This component provides essential tips for effective sample collection.
 * It displays a series of tips based on the type of barcode selected (lavender or urine),
 * and allows users to activate the sample collection process by submitting necessary information.
 * Additionally, it presents instructional content to improve blood flow during sample collection.
 *
 * @returns {JSX.Element} The rendered component for displaying essential sample collection tips.
 */

const EssenTialTips = () => {
  const primaryBarcode = useParams();
  const [secondaryType, setSecondaryType] = useState(
    localStorage.getItem("secondary_barcode_type") || ""
  );
  const { callApi, resultPost } = useApis();
  const postApiUrl = `/api/dashboard/activating/`;
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const payload = {
      activation_stage: "collect-sample",
      code: primaryBarcode.primaryBarcode
    };
    await callApi(payload, postApiUrl, "POST");
  };

  useEffect(() => {
    if (resultPost) {
      navigate(`/time-administered/${primaryBarcode.primaryBarcode}`)
    }
  }, [resultPost]);

  useEffect(() => {
    setSecondaryType(localStorage.getItem("secondary_barcode_type") || "");
  }, [secondaryType]);

  /**
   * getTips function
   *
   * This function determines the number of tips to display and the video link
   * based on the type of secondary barcode. It returns an object containing
   * the count of tips, a flag to show lavender tips, and the video link.
   *
   * @returns {Object} An object containing tipsCount, showLavender, and videoLink.
   */

  const getTips = () => {
    let tipsCount = "10";
    let showLavender = false;
    let videoLink = "https://youtu.be/XTv13r3o8CU";

    if (secondaryType === "lavender") {
      tipsCount = "12";
      showLavender = true;
      videoLink = "https://youtu.be/qUiiU0htNvc";
    } else if (secondaryType === "urine") {
      tipsCount = "10";
      videoLink = "https://youtu.be/7MZCX6BlZHE";
    }

    return { tipsCount, showLavender, videoLink };
  };

  const { tipsCount, showLavender, videoLink } = getTips();

  return (
    <div className="row justify-content-center py-5 essential-tips-page">
      <div className="col-10">
        <h1 className="mb-4">
          Sample collection success: {tipsCount} essential tips
        </h1>
        <div className="pt-sm-4 pl-sm-1 pt-md-2 pb-5 bloods-text">
          Whilst you should always follow your instructions for use leaflet,
          it's important to remember these tips to ensure you collect a sample
          that is optimal for lab analysis. Stimulating blood flow is critical
          for effective sampling.
          <br />
          <br />
          <p>
            You can watch our ‘How to’ video{" "}
            <a
              className="video-link"
              href={videoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              HERE
            </a>
          </p>
        </div>

        <Row>
          <Col lg={12}>
            <div>
              <div className="mb-4 pt-4">
                <h1 className="text-md-left subtitle">
                  Prep for better blood flow:
                </h1>
              </div>
              <Row>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={_45MinCircle}
                    alt="45 Min circle"
                  />
                  <h1 className="mt-2 points-title">1. Give yourself time:</h1>
                  <div className="mt-2 mb-2 points-text">
                    Please ensure you have left enough time to perform this
                    test. <strong>This can take up to 45 minutes.</strong>
                  </div>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={drinkWaterCircle}
                    alt="Drinking water circle"
                  />
                  <h1 className="mt-2 points-title">
                    2. Drink plenty of water:
                  </h1>
                  <div className="mt-2 mb-2 points-text">
                    The day before: <strong>6-8</strong> glasses The morning of
                    your test: <strong>2</strong> glasses
                  </div>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={washHandscircle}
                    alt="Wash hands circle"
                  />
                  <h1 className="mt-2 points-title">3. Warm your hands:</h1>
                  <div className="mt-2 mb-2 points-text">
                    Soak in warm water for <strong>2-3</strong> minutes.
                  </div>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={exerciseCircle}
                    alt="Exercise circle"
                  />
                  <h1 className="mt-2 points-title">
                    4. Do some light exercise:
                  </h1>
                  <div className="mt-2 mb-2 points-text">
                    Go for a walk, swing your arms, or clench your fists.
                  </div>
                </Col>
              </Row>

              <div className="mb-4 pt-4">
                <h1 className="text-md-left subtitle">
                  Pointers when collecting your sample:
                </h1>
              </div>
              <Row>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={sitcomfortably}
                    alt="Sit comfortable circle"
                  />
                  <h1 className="mt-2 points-title">5. Sit comfortably:</h1>
                  <div className="mt-2 mb-2 points-text">
                    Place your arm in a <strong>downward position</strong>,
                    using a table or armrest to support.
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-sit-comfortably">
                        This position encourages blood flow to your fingers.
                      </Tooltip>
                    }
                  >
                    <span className="tooltip-text d-inline-block mb-4">
                      See Why
                    </span>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={puncturePositionCircle}
                    alt="Puncture position circle"
                  />
                  <h1 className="mt-2 points-title">
                    6. Puncture the side of your finger:
                  </h1>
                  <div className="mt-2 mb-2 points-text">
                    Use your <strong>middle</strong> or{" "}
                    <strong>ring finger</strong> on your non-dominant hand.
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-puncture-position">
                        These fingers have better blood flow and are less
                        sensitive to pain.
                      </Tooltip>
                    }
                  >
                    <span className="tooltip-text d-inline-block mb-4">
                      See Why
                    </span>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={milkingFingerCircle}
                    alt="Milking finger circle"
                  />
                  <h1 className="mt-2 points-title">
                    7. Do not squeeze your finger:
                  </h1>
                  <div className="mt-2 mb-2 points-text">
                    Use a milking action instead.
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-squeeze-finger">
                        Squeezing can spoil samples and cause bruising.
                      </Tooltip>
                    }
                  >
                    <span className="tooltip-text d-inline-block mb-4">
                      See Why
                    </span>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <img
                    className="m-2 check-list-img"
                    src={wipeFingerCircle}
                    alt="Wipe finger circle"
                  />
                  <h1 className="mt-2 points-title">
                    8. Discard the first drop:
                  </h1>
                  <div className="mt-2 mb-2 points-text">
                    Wipe with a clean tissue.
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <h1 className="mt-2 subtitle gold">Gold tube first:</h1>
                  <Row>
                    <Col md={6}>
                      <img
                        className="m-2 check-list-img"
                        src={goldTubeCircle}
                        alt="Gold tube circle"
                      />
                      <h1 className="mt-2 points-title">
                        9. Collect the gold tube:
                      </h1>
                      <div className="mt-2 mb-2 points-text">
                        Fill the gold tube to the line indicated on the side.
                      </div>
                    </Col>
                    <Col md={6}>
                      <img
                        className="m-2 check-list-img"
                        src={collectSampleGT}
                        alt="Collect sample circle"
                      ></img>
                      <h1 className="mt-2 points-title">10. Gently mix:</h1>
                      <div className="mt-2 mb-2 points-text">
                        Turn upside down 10 times.
                      </div>
                    </Col>
                  </Row>
                </Col>
                {showLavender && (
                  <Col lg={6}>
                    <h1 className="mt-2 subtitle lavender">
                      Then lavender tube:
                    </h1>
                    <Row>
                      <Col md={6}>
                        <img
                          className="m-2"
                          src={newLancetCircle}
                          alt="New finger circle"
                        ></img>
                        <h1 className="mt-2 points-title">
                          11. Use a new finger:
                        </h1>
                        <div className="mt-2 mb-2 points-text">
                          New finger = new lancet.
                        </div>
                        <div>
                          <p>
                            <span
                              id="UncontrolledTooltip11"
                              className="tooltip-text"
                            >
                              See Why
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <img
                          className="m-2"
                          src={newFingerLT}
                          alt="New lacent circle"
                        ></img>
                        <h1 className="mt-2 points-title">12. Gently mix:</h1>
                        <div className="mt-2 mb-2 points-text">
                          After each drop, swirl 10 times.
                        </div>
                        <div>
                          <p>
                            <span
                              id="UncontrolledTooltip12"
                              className="tooltip-text"
                            >
                              See Why
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <div className="pt-4 pb-5 d-flex justify-content-end">
                <Button
                  label={"Continue"}
                  className="sample-collection-button"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EssenTialTips;
