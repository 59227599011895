import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./assets/scss/main.scss";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/AuthContext/AuthContext";

// Initialize Sentry
Sentry.init({
  dsn: "https://9ff4845706918c8cdcc53d75cf36f8ab@o4508289391067136.ingest.de.sentry.io/4508289392836688",
  tracesSampleRate: 1.0, // Set to 1.0 for full capture; adjust for production
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  </BrowserRouter>
);

// For performance monitoring
reportWebVitals();
