import * as yup from "yup";
import "yup-phone-lite";
import { format, subDays } from "date-fns";

// Regular expression to check for consecutive sequences in passwords
const sequence = /^(?!.*(?:012|123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)).*$/;

// Login validation schema
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email(),
  password: yup.string().required("Password is required")
});

// Password validation schema with various criteria
const passwordValidation = yup
  .string()
  .required("Password is required")
  .matches(/^[^\s]+$/, "Password must not contain any spaces")
  .matches(
    /^(?!.*(\w)\1{2,})/,
    "Password must not contain repetitive characters"
  )
  .matches(
    sequence,
    "Password should not contain sequences of three or more consecutive letters or numbers."
  )
  .matches(
    /^(?=.*[A-Z])/,
    "Password must contain at least one uppercase letter"
  )
  .matches(
    /^(?=.*[a-z])/,
    "Password must contain at least one lowercase letter"
  )
  .matches(
    /^(?=.*[@$!%*?&])/,
    "Password must contain at least one special character"
  )
  .matches(/^(?=.*\d)/, "Password must contain at least one number")
  .min(8, "Minimum 8 characters");

// Create account validation schema
export const createAccountSchema = yup.object().shape({
  firstname: yup.string().required("First Name is required"),
  lastname: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email(),
  password: passwordValidation
});

// Reset password validation schema
export const resetPassSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email()
});

// Activation validation schema
export const activationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .matches(/^[^\s]+$/, "Password must not contain any spaces")
    .matches(
      /^(?!.*(\w)\1{2,})/,
      "Password must not contain repetitive characters"
    )
    .matches(
      /^(?!.*(?:abcdefghijklmnopqrstuvwxyz|ABCDEFGHIJKLMNOPQRSTUVWXYZ|01234567890))[\w@$!%*?&]{8,}$/,
      "Password must not contain sequential characters"
    ),
  occupation: yup.string().required("Occupation is required"),
  credentials: yup.string().required("Credentials is required"),
  organisation: yup.string().required("Organisation is required"),
  mobile: yup
    .string()
    .phone("GB", "Please enter a valid UK phone number")
    .required("Phone number is required"),
  name: yup.string("Name cannot be a number").required("Name is required")
});

// Schema for reset password validation
export const setPasswordSchema = yup.object().shape({
  password: passwordValidation,
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required")
});

// Personal details validation schema
export const personalDetailsSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),

  last_name: yup.string().required("Last Name is required"),
  phone_number_primary: yup.string()
    .required('Phone number is required')
    .test('not-only-prefix', 'Phone number cannot be just the prefix', value => {
      return value && value.trim() !== '' && value.trim() !== '+44';
    }),
  dob: yup
    .date()
    .required("Date of Birth is required")
    .test("age", "You must be at least 18 years old", value => {
      return (
        value && new Date().getFullYear() - new Date(value).getFullYear() >= 18
      );
    }),

  sex: yup.string().required("Gender at Birth is required"),

  identify_with_gender: yup
    .string()
    .required("You must indicate if you still identify with this gender"),

  ethnicity: yup.string().required("Ethnicity is required")
});

// Personal details validation schema
// export const personalDetailsShSchema = yup.object().shape({
//   first_name: yup.string().required("First Name is required"),

//   last_name: yup.string().required("Last Name is required"),

//   dob: yup
//     .date()
//     .required("Date of Birth is required")
//     .test("age", "You must be at least 18 years old", value => {
//       return (
//         value && new Date().getFullYear() - new Date(value).getFullYear() >= 18
//       );
//     }),

//   sex: yup.string().required("Gender at Birth is required"),

//   identify_with_gender: yup
//     .string()
//     .required("You must indicate if you still identify with this gender"),

//   ethnicity: yup.string().required("Ethnicity is required"),

//   phone_number_primary: yup
//     .string()
//     .required("Phone number is required")
//     .test(
//       "not-only-prefix",
//       "Phone number cannot be just the prefix",
//       value => {
//         return value && value.trim() !== "" && value.trim() !== "+44";
//       }
//     )
// });

// Personal details validation schema
// export const personalDetailsShSchema = yup.object().shape({
//   first_name: yup.string()
//     .required('First Name is required'),

//   last_name: yup.string()
//     .required('Last Name is required'),

//   dob: yup.date()
//     .required('Date of Birth is required')
//     .test('age', 'You must be at least 18 years old', value => {
//       return value && new Date().getFullYear() - new Date(value).getFullYear() >= 18;
//     }),

//   sex: yup.string()
//     .required('Gender at Birth is required'),

//   identify_with_gender: yup.string()
//     .required('You must indicate if you still identify with this gender'),

//   ethnicity: yup.string()
//     .required('Ethnicity is required'),
// });

// Personal details validation schema
export const personalDetailsShSchema = yup.object().shape({
  first_name: yup.string()
    .required('First Name is required'),

  last_name: yup.string()
    .required('Last Name is required'),

  dob: yup.date()
    .required('Date of Birth is required')
    .test('age', 'You must be at least 18 years old', value => {
      return value && new Date().getFullYear() - new Date(value).getFullYear() >= 18;
    }),

  sex: yup.string()
    .required('Gender at Birth is required'),

  identify_with_gender: yup.string()
    .required('You must indicate if you still identify with this gender'),

  ethnicity: yup.string()
    .required('Ethnicity is required'),

  phone_number_primary: yup.string()
    .required('Phone number is required')
    .test('not-only-prefix', 'Phone number cannot be just the prefix', value => {
      return value && value.trim() !== '' && value.trim() !== '+44';
    }),

});

// Edit account details validation schema
export const editDetailsSchema = yup.object().shape({
  first_name: yup.string()
    .required('First Name is required'),

  last_name: yup.string()
    .required('Last Name is required'),

  dob: yup.date()
    .required('Date of Birth is required')
    .test('age', 'You must be at least 18 years old', value => {
      return value && new Date().getFullYear() - new Date(value).getFullYear() >= 18;
    }),

  sex: yup.string()
    .required('Gender at Birth is required'),

  identify_with_gender: yup.string()
    .required('You must indicate if you still identify with this gender'),

  ethnicity: yup.string()
    .required('Ethnicity is required'),

  phone_number_primary: yup.string()
    .required('Phone number is required')
    .test('not-only-prefix', 'Phone number cannot be just the prefix', value => {
      return value && value.trim() !== '' && value.trim() !== '+44';
    }),

  phone_number_secondary: yup.string()
    .notRequired()
    .test('valid-uk-phone', 'Please provide a valid UK phone number', value => {
      if (!value) return true;
      const ukPhoneRegex = /^(\+44|0)\d{10}$/; // Matches +44 or 0 followed by exactly 10 digits
      return ukPhoneRegex.test(value.trim());
    }),
});

const today = new Date();
const fifteenDaysAgo = subDays(today, 15);

// Schema for reset password validation
export const timeAdministeredSchema = yup.object().shape({
  sample_collection_date: yup
    .date()
    .required("Date of test taken is required")
    .max(today, "Date cannot be in the future")
    .min(fifteenDaysAgo, "Date cannot be older than 15 days"),

  sample_collection_time: yup
    .string()
    .required("Time of test taken is required")
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Time format must be HH:mm")
});

// export const barcodeValidationSchema = pathname => {
//   return yup.object().shape({
//     code: yup
//       .string()
//       .test(
//         "is-valid-code",
//         "Invalid barcode format, should be 5 letters followed by 5 digits",
//         function(value) {
//           if (pathname?.includes("/enter-barcode/dna")) {
//             return /^[A-Z]{5}\d{5}$/.test(value);
//           }
//           // Add additional conditions if necessary
//           return true; // Ensure to return true for other cases
//         }
//       )
//       .required("Barcode is required"),
//     trackingNumber: yup
//       .string()
//       .matches(
//         /^[A-Z]{2}\s\d{4}\s\d{4}\s\d[A-Z]{2}$/,
//         "Tracking number must be in the format AA 1234 5678 9BB"
//       )
//       .required("Tracking number is required")
//   });
// };

export const bodyAssessmentSchema = yup.object().shape({
  height: yup
    .number()
    .required("Height is required")
    .when("units", {
      is: "Imperial",
      then: schema =>
        schema
          .min(20, "Value must be greater than or equal to 20")
          .max(1100, "Value must be less than or equal to 110"),
      otherwise: schema =>
        schema
          .min(50, "Value must be greater than or equal to 50")
          .max(300, "Value must be less than or equal to 300")
    }),
  weight: yup
    .number()
    .required("Weight is required")
    .when("units", {
      is: "Imperial",
      then: schema =>
        schema
          .min(40, "Value must be greater than or equal to 40")
          .max(1110, "Value must be less than or equal to 1110"),
      otherwise: schema =>
        schema
          .min(20, "Value must be greater than or equal to 20")
          .max(500, "Value must be less than or equal to 500")
    }),
  // units: yup.string().required("Unit is required"),
  smoking: yup.string().required("Smoking status is required"),
  cigarettes_per_day: yup.string().when("smoking", {
    is: "Yes",
    then: schema => schema.required("Cigarette count is required"),
    otherwise: schema => schema
  }),
  alcohol: yup.string().required("Alcohol consumption status is required"),
  alcohol_units_per_week: yup.string().when("alcohol", {
    is: "Yes",
    then: schema => schema.required("Alcohol consumption count is required"),
    otherwise: schema => schema
  })
});
export const shSchema = yup.object().shape({
  sexually_active: yup.string().required('This field is required.'),
  partners: yup.string().when('sexually_active', {
    is: 'Yes',
    then: schema => schema.required('This field is required when sexually active.'),
  }),
  recent_sex: yup.string().when('sexually_active', {
    is: 'Yes',
    then: schema => schema.required('This field is required when sexually active.'),
  }),
  had_unprotected_sex: yup.string().when('sexually_active', {
    is: 'Yes',
    then: schema => schema.required('This field is required when sexually active.'),
  }),
  unprotected_sex_type: yup.array().default([]).when('had_unprotected_sex', {
    is: 'Yes',
    then: schema => schema
      // .of(yup.string())
      .min(1, 'Please select at least one option if you had unprotected sex.'),
  }),
  sti_symptoms: yup.string().required('This field is required.'),
  sti_symptoms_description: yup.string().when('sti_symptoms', {
    is: 'Yes',
    then: schema => schema.required('Please provide details of your symptoms.'),
  }),
  sti_diagnosed: yup.string().required('This field is required.'),
  potential_sti_exposure: yup.string().required('This field is required.'),
  checkbox_group: yup.array().default([]).when('potential_sti_exposure', {
    is: 'Yes',
    then: schema => schema
      // .of(yup.b())
      .min(1, 'Please select at least one option if you think you may have been exposed to an STI.'),
  }),
});




export const menstrualSchema = yup.object().shape({
  cycle_length: yup.string()
    .required('Please select your normal cycle length.'),
  day_of_cycle: yup.string()
    .required('Please select the day of your cycle when this blood test was taken.'),
  contraception: yup.string()
    .required('Please select your contraception status.'),
});

export const barcodeValidationSchema = (pathname) => {
  return yup.object().shape({
    code: yup.string()
      .test("is-valid-code", "Invalid barcode format, should be 5 letters followed by 5 digits", function (value) {
        if (pathname?.includes("/enter-barcode/dna")) {
          return /^[A-Z]{5}\d{5}$/.test(value);
        }
        // Add additional conditions if necessary
        return true; // Ensure to return true for other cases
      })
      .required("Barcode is required"),
    trackingNumber: yup.string()
      .matches(/^[A-Z]{2}\s\d{4}\s\d{4}\s\d[A-Z]{2}$/, "Tracking number must be in the format AA 1234 5678 9BB")
      .required("Tracking number is required"),
  });
};

export const exerciseSchema = yup.object().shape({
  physical_activity: yup
    .string()
    .oneOf(['Yes', 'No'], 'Please select Yes or No')
    .required('Physical activity status is required'),
  light_activity_hours_per_week: yup.string().when("physical_activity", {
    is: "Yes",
    then: schema => schema.required('Light activity hours are required')
      .oneOf(
        ['0', '1', '2', '3', '4', '5', '6', '7', '7+'],
        'Please select a valid number of hours'
      )
    ,
    otherwise: schema => schema
  }),
  moderate_activity_hours_per_week: yup.string().when("physical_activity", {
    is: "Yes",
    then: schema => schema.required('Moderate activity hours are required')
      .oneOf(
        ['0', '1', '2', '3', '4', '5', '6', '7', '7+'],
        'Please select a valid number of hours'
      )
  }),
  vigorous_activity_hours_per_week: yup.string().when("physical_activity", {
    is: "Yes",
    then: schema => schema.required('Vigorous activity hours are required')
  })
    .oneOf(
      ['0', '1', '2', '3', '4', '5', '6', '7', '7+'],
      'Please select a valid number of hours'
    ),
});

// Personal details validation schema
export const phlebotomyDetailsShSchema = yup.object().shape({
  first_name: yup.string()
    .required('First Name is required'),

    last_name: yup.string()
    .required('Last Name is required'),

  dob: yup.date()
    .required('Date of Birth is required')
    .test('age', 'You must be at least 18 years old', value => {
      return value && new Date().getFullYear() - new Date(value).getFullYear() >= 18;
    }),

  sex: yup.string()
    .required('Sex at Birth is required'),

  identify_with_gender: yup.string()
    .required('You must indicate if you still identify with this gender'),

  phone_number_primary: yup.string()
    .required('Phone number is required')
    .test('not-only-prefix', 'Phone number is required', value => {
      return value && value.trim() !== '' && value.trim() !== '+44';
    }),
});