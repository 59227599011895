import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const GenderWarningModal = ({ heading, isOpen, toggle, message }) => {
  return (
    <Modal show={isOpen} onHide={toggle} className='gender-warning-modal'>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenderWarningModal;
