import React from "react";
import { Button } from "../../stories/Buttons/Button";

// Displays the download section for reports
const ReportDownloadSection = () => {
  return (
    <div>
      <Button
        className="me-2 float-right btn btn-outline-primary"
        label="Download raw data"
      />
      <Button
        className="float-right btn btn-primary"
        label="Generating PDF Report"
      />
    </div>
  );
};

export default ReportDownloadSection;
