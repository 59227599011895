// src/utils/scaleUtils.js
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
export const isSingleBoundaryScale = (params) => {
  return params.type === 'low-normal' || params.type === 'normal-high';
};

export const isNegativePositiveScale = (params) => {
  return params.type === 'negative-positive';
};

export const trimToRange = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export const getScaleDetails = (params) => {
  let { value, boundary, lower_boundary, upper_boundary, result, decision_type } = params;

  if (decision_type === 'female-manual') {
    lower_boundary = 2;
    upper_boundary = 4;
    value = 3;
    if (result === 'low') value = 1;
    else if (result === 'normal') value = 3;
    else if (result === 'high') value = 5;
  }

  let barSize = 50;
  let leftBarSize = 0;

  if (isNegativePositiveScale(params)) {
    return {
      pointerPosition: result === 'green' ? 25 : 75,
      barSize: 50,
    };
  }

  if (isSingleBoundaryScale(params)) {
    const pointerPosition = (value / boundary / 2) * 100;
    return {
      pointerPosition: trimToRange(pointerPosition, 0, 100),
      barSize,
      leftBarSize,
    };
  }

  let delta = upper_boundary - lower_boundary;
  let min = lower_boundary - delta;
  if (min < 0) min = 0;
  let max = upper_boundary + delta;

  leftBarSize = ((lower_boundary - min) / (max - min)) * 100;
  barSize = (100 - leftBarSize) / 2;
  const pointerPosition = ((value - min) / (max - min)) * 100;

  return {
    pointerPosition: trimToRange(pointerPosition, 2, 98),
    barSize,
    leftBarSize,
  };
};

// scaleUtils.js
export const renderRangeLabel = (decision_type, type, lower_boundary, upper_boundary) => {
  const boundary = (lower_boundary + upper_boundary) / 2;

  if (decision_type === 'female-manual') return null;

  if (type === 'low-normal-high') {
    return `${lower_boundary?.toFixed(2)} - ${upper_boundary?.toFixed(2)}`;
  }

  if (type === 'low-normal') {
    return `> ${boundary?.toFixed(2)}`;
  }

  if (type === 'normal-high') {
    return `< ${boundary?.toFixed(2)}`;
  }

  return null;
};



export const getStatusIcon = (status) => {
  return ['good', 'normal'].includes(String(status)) ? faCheck : faExclamation;
};
