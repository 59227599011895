import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../components/AuthContext/AuthContext";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { EmojiSmile } from "react-bootstrap-icons";

import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  assessementFinalSubmit,
  phlebotomyAssessmentStatusUrl,
  phlebotomyAssessementFinalSubmit
} from "../../utils/constants";
import useApis from "../../services/useAPI";
import SwitchAssessmentStep from "./SwitchAssessmentStep";
let submissionData;

const StartAssessment = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { test_kit_id } = params;
  const [isCompleted, setIsCompleted] = useState(0);
  const [step, setStep] = useState(auth.completedSteps);
  const [isShTest, setIsShTest] = useState();
  const [isHormoneTest, setIsHormoneTest] = useState();
  const [allStepData, setAllStepData] = useState({});
  const { bid } = useParams();
  const { test_type } = useParams();

  // Determines if the current user is a phlebotomist.
  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  const { callApi, resultGet, resultPost, serverError } = useApis();

  const handleNextStep = useCallback(() => {
    setIsCompleted(1); // Increment the step based on the latest value
    auth.setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }, [auth]);

  const handlePreviousStep = useCallback(() => {
    auth.setCompletedSteps(prevSteps => prevSteps - 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep - 1);
  }, [auth]);

  const handleAssessmentSubmit = values => {
    // Remove keys with undefined values
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    // Prepare data based on the current step
    switch (step) {
      case 1:
        return; // Return early if step 1 does not require submission

      case 2:
        submissionData = {
          body: {
            height: values.height,
            weight: values.weight,
            units: values?.units || "Metric"
          },
          smoking: {
            smoking: values.smoking,
            cigarettes_per_day: values.cigarettes_per_day
          },
          alcohol: {
            alcohol: values.alcohol,
            alcohol_units_per_week: values.alcohol_units_per_week
          },
          step: "body_smoking_alcohol"
        };
        setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        break;

      case 3:
        submissionData = {
          activity: {
            physical_activity: values?.physical_activity,
            light_activity_hours_per_week:
              values?.light_activity_hours_per_week || null,
            moderate_activity_hours_per_week:
              values?.moderate_activity_hours_per_week || null,
            vigorous_activity_hours_per_week:
              values?.vigorous_activity_hours_per_week || null
          },
          step: "activity"
        };
        setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        break;

      case 4:
        submissionData = {
          medical_conditions: {
            condition: values.medical_conditions.conditions.medical_conditions_group

          },
          was_diagnosed: values.medical_conditions.was_diagnosed,
          step: "medical_conditions"
        };
        setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        break;

      case 5:
        submissionData = {
          medications: {
            medications: values.medications.medications.medications_group || [], // Default to an empty array
            details: values.medications.other_description || null // Ensure details are included
          },
          step: "medications"
        };
        setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        break;

      case 6:
        if (isShTest) {
          submissionData = {
            sexual_health: {
              sexually_active: values?.sexually_active || null,
              partners: values?.partners || null,
              recent_sex: values?.recent_sex || null,
              had_unprotected_sex: values?.had_unprotected_sex || null,
              unprotected_sex_type: values?.unprotected_sex_type || null,
              sti_diagnosed: values?.sti_diagnosed || null,
              sti_symptoms: values?.sti_symptoms || null,
              sti_symptoms_description:
                values?.sti_symptoms_description || null,
              potential_sti_exposure: values?.potential_sti_exposure || null,
              potential_sti_exposure_type:
                values?.potential_sti_exposure_type || null
            },
            step: "sexual_health"
          };
          setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        } else if (isHormoneTest) {
          submissionData = {
            menstrual: {
              cycle_length: values.cycle_length,
              day_of_cycle: values.day_of_cycle,
              contraception: values.contraception
            },
            step: "menstrual"
          };
          setAllStepData(prevStep => ({ ...prevStep, ...submissionData }));
        }
        break;

      default:
        return; // Return early if there's no matching case
    }

    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
  };

  useEffect(() => {
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else {
      location?.state?.test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
        callApi(
          null,
          `${assessmentStatusUrl}${test_kit_id ||
            localStorage.getItem("test_kit_id")}`,
          "GET",
          true
        );
    }
  }, [step, location?.state?.test_kit_id]);


  useEffect(() => {
    if(!test_type && !bid)
    {
      if (resultGet?.test_kit_details.test_kit_type.includes("sex")) {
        setIsShTest(true);
        setIsHormoneTest(false);
      }
      if (
        resultGet?.test_kit_details.test_kit_type.includes(
          "menopause" || "polysystic"
        )
      ) {
        setIsHormoneTest(true);
        setIsShTest(false);
      }
    }
  }, [resultGet, step]);

  useEffect(() => {
    if (resultPost) {
      setIsCompleted(step);
      auth.setCompletedSteps(auth.completedSteps + 1);
      setStep(prevStep => prevStep + 1);
    }
  }, [resultPost, isHormoneTest, isShTest]);

  // Handles the final submission of all assessment data.
  const finalSubmit = () => {

    if(isPhlebotomy && test_type && bid)
    {
      callApi(allStepData , phlebotomyAssessementFinalSubmit(bid) , "POST");
      if(resultPost && test_type && bid)
      {
        auth?.showToast(
          "Assessment Completed",
          "All steps completed successfully",
          "../assets/mhc.png",
          <EmojiSmile />
      );
        navigate(`/bloods/boots/success-page/${test_type}/${bid}`);
      }
    }
    else {
      callApi(
        {
          ...allStepData
        },
        `${assessementFinalSubmit}/${
          test_kit_id ? test_kit_id : location?.state?.test_kit_id
        }`,
        "POST",
        true
      );
    }
    
    if (resultPost && !test_type && !bid) {
      navigate(`/assessment-details/${test_kit_id ? test_kit_id : location?.state?.test_kit_id}`);
    }
  };

  return (
    <div className="row justify-content-center pt-4 start-assessment-page">
      <div className="col-10">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={step}
          completedSteps={auth.completedSteps}
          isCompleted={isCompleted}
        />
        <SwitchAssessmentStep
          step={step}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          handleAssessmentSubmit={handleAssessmentSubmit}
          isShTest={isShTest}
          isHormoneTest={isHormoneTest}
          initialValues={resultGet?.assessment_values}
          allStepData={allStepData}
          finalSubmit={finalSubmit}
        />
      </div>
    </div>
  );
};

export default StartAssessment;
