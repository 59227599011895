import React from "react";
import { ProgressBar } from "react-bootstrap";
import "../../../../assets/scss/_marker-scale.scss";

/**
 * MarkerProgressBar component
 * This component renders a customizable progress bar with optional boundary indicators.
 * It visually represents the progress with different styles based on the provided props.
 *
 * @returns {JSX.Element} The rendered progress bar component.
 */

const MarkerProgressBar = ({
  showLowBoundary,
  leftBarSize,
  barSize,
  showHighBoundary,
  value
}) => (
  <ProgressBar className="my-0 markerScale__bar-container">
    {showLowBoundary && (
      <ProgressBar
        className="mr-0 markerScale__bar markerScale__bar--bad"
        now={leftBarSize || barSize}
      />
    )}
    <ProgressBar className="mx-0 markerScale__bar" now={barSize} />
    {showHighBoundary && (
      <ProgressBar
        className={`ml-0 markerScale__bar markerScale__bar--${
          value === "Unconfirmed Reactive" ? "unconfirmed" : "bad"
        }`}
        now={barSize}
      />
    )}
  </ProgressBar>
);

export default MarkerProgressBar;
