import React from "react";
import { LinkCard } from "../stories/cards/LinkCard";
import DNA from "../assets/icons/Platform/Activate_Wellness_DNA.svg";
import Blood from "../assets/icons/HealthAreas/LDL_Levels.svg";
import SH from "../assets/icons/Platform/Activate_SH.svg";

const ActivateTestKit = () => {
  return (
    <div className="pt-sm-4 mt-4 mb-4">
      <div className="container activate-box-wrapper">
        <div className="row ">
          <div className="col-md-12">
            <h1 className="text-left py-4">Activate my test</h1>
          </div>

          <div className="col-md-12">
            {/* LinkCard for activating a blood test */}
            <LinkCard
              to="/tests/bloods/activate"
              image={Blood}
              title="Activate a blood test"
              button_text="Activate kit"
              button_append_class="my-button-class"
            >
              <p>
                If you have received a blood test kit, you can activate it now.
              </p>
            </LinkCard>
          </div>
          <div className="col-md-12">
            {/* LinkCard for activating a sexual health test */}
            <LinkCard
              to="/tests/sh/activate"
              image={SH}
              title="Activate a sexual health test"
              button_text="Activate kit"
              button_append_class="my-button-class"
            >
              <p>
                If you have received a sexual health Test kit, you can activate
                it now.
              </p>
            </LinkCard>
          </div>

          <div className="col-md-12">
            {/* LinkCard for activating a DNA test */}
            <LinkCard
              to="/tests/dna/activate"
              image={DNA}
              title="Activate a DNA test"
              button_text="Activate kit"
              button_append_class="my-button-class"
            >
              <p>
                If you have received a DNA test kit, you can activate it now.
              </p>
            </LinkCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateTestKit;
