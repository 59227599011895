import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { assessmentStatusUrl, phlebotomyAssessmentStatusUrl } from "../../utils/constants";
import useApis from "../../services/useAPI";
import { Button } from "../../stories/Buttons/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/AuthContext/AuthContext";

function prettifyValue(rawValue) {
  let val = rawValue;
  // Join array items into a string
  if (Array.isArray(val)) {
    val = val.join(", ");
  }

  // Replace dashes with spaces
  val = String(val || "").replace("_", " ");
  val = toTitleCase(val);
  return val;
}

function toTitleCase(str) {
  return String(str)
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      if (!word.length) return word;

      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

const lookupTable = {
  "Height & Weight": 1,
  Smoking: 1,
  Alcohol: 1,
  "Physical activity": 2,
  "Medical conditions": 3,
  Medications: 4,
  Menstrual: 5,
  "Sexual health": 5
};
export function SimpleAssessmentSummary() {
  const { callApi, resultGet } = useApis();
  const [assessmentData, setAssessmentData] = useState({}); // Initialize state for assessment data
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const { test_type, bid } = useParams();
  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  useEffect(() => {
    const test_kit_id = location.pathname.split("/")[2];
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else if (test_kit_id && !test_type && !bid) {
      callApi(null, `${assessmentStatusUrl}${test_kit_id}`, "GET", true);
    }
  }, []);

  useEffect(() => {
    if (resultGet && isPhlebotomy && test_type && bid) {
      const values = resultGet?.assessment_values;
      const personal_details = resultGet?.phlebotomist_booking_info;
      setAssessmentData({
        "Height & Weight": {
          units: values?.body?.units,
          height: values?.body?.height,
          weight: values?.body?.weight
        },
        Smoking: {
          "Do you smoke cigarettes ?": values?.smoking?.smoking,
          "How many cigarettes do you (or did you) smoke each day?":
            values?.smoking?.cigarettes_per_day
        },
        Alcohol: {
          "Do you drink alcohol?": values?.alcohol?.alcohol,
          "How many units do you drink each week?":
            values?.alcohol?.alcohol_units_per_week
        },
        "Physical activity": {
          "Do you do any physical activity?":
            values?.activity?.physical_activity,
          "Light activity, e.g. walking, stretching":
            values?.activity?.light_activity_hours_per_week,
          "Moderate activity, e.g. aerobics, golf":
            values?.activity?.moderate_activity_hours_per_week,
          "Vigorous activity, e.g. running, weight training":
            values?.activity?.vigorous_activity_hours_per_week
        },
        "Medical conditions": {
          "Please tick all that apply": [
            ...values?.medical_conditions?.condition
          ]
        },
        Medications: {
          "Please tick all that apply": [...values?.medications?.medications],
          "Please tell us the name, dosage and frequency of your medication":
            values?.medications?.details
        },
        "Personal Details": {
          "First Name": [personal_details?.first_name],
          "Last Name": [personal_details?.last_name],
          "Date of Birth": [personal_details?.dob],
          "Sex at Birth": [personal_details?.sex],
          "Do you still identify with this gender?": [personal_details?.identify_with_gender]
        },
        "Test Type": {
          "Test Type": [personal_details?.test_type]
        }
      }); // Set the state when data is available
    }
    else if (resultGet && resultGet.assessment_values && !test_type && !bid) {
      const values = resultGet?.assessment_values;
      setAssessmentData({
        "Height & Weight": {
          units: values?.body?.units,
          height: values?.body?.height,
          weight: values?.body?.weight
        },
        Smoking: {
          "Do you smoke cigarettes ?": values?.smoking?.smoking,
          "How many cigarettes do you (or did you) smoke each day?":
            values?.smoking?.cigarettes_per_day
        },
        Alcohol: {
          "Do you drink alcohol?": values?.alcohol?.alcohol,
          "How many units do you drink each week?":
            values?.alcohol?.alcohol_units_per_week
        },
        "Physical activity": {
          "Do you do any physical activity?":
            values?.activity?.physical_activity,
          "Light activity, e.g. walking, stretching":
            values?.activity?.light_activity_hours_per_week,
          "Moderate activity, e.g. aerobics, golf":
            values?.activity?.moderate_activity_hours_per_week,
          "Vigorous activity, e.g. running, weight training":
            values?.activity?.vigorous_activity_hours_per_week
        },
        "Medical conditions": {
          "Please tick all that apply": [
            ...values?.medical_conditions?.condition
          ]
        },
        Medications: {
          "Please tick all that apply": [...values?.medications?.medications],
          "Please tell us the name, dosage and frequency of your medication":
            values?.medications?.details
        }
      }); // Set the state when data is available
    }
  }, [resultGet]);

  // Render each section
  function renderSection(title, fields) {
    console.log(title);
    
    return (
      <Col xs={12} md={6} lg={4} className="mb-4">
        <div className="p-3 shadow-sm bg-white rounded">
          <div className="d-flex gap-3">
            {" "}
            <h5 className="mb-3 font-weight-bold">{title}</h5>
            {resultGet?.can_be_edited && (
              <Button
                className={"w-25 logout-button"}
                edit={true}
                variant={"outline-primary"}
                label={"Edit"}
                onClick={() => {
                  auth.setCompletedSteps(lookupTable[title] + 1);
                  // navigate(`/assessment`);
                  if (isPhlebotomy && test_type && bid) {
                    if (title === 'Test Type') {
                      navigate(`/bloods/boots/registration/${test_type}?edit=true`)
                    }
                    else if (title === 'Personal Details') {
                      navigate(`/bloods/boots/${test_type}/confirm-details/${bid}?edit=true`)
                    }
                    else if (resultGet?.assessment_values) {
                      navigate(`/bloods/boots/assessment-form/${test_type}/${bid}`);
                    }
                  } else {
                    navigate(`/assessment`);
                  }
                }}
              />
            )}
          </div>
        </div>
        <div>
          {Object.keys(fields).map(f => {
            const prettyValue = prettifyValue(fields[f]);
            if (!prettyValue) return null;

            return (
              <p key={f} className="mt-1">
                <small>
                  {f.replace("_", " ").replace(/\b\w/g, c => c.toUpperCase())}
                </small>{" "}
                <br />
                <strong className="">{prettyValue}</strong>
              </p>
            );
          })}
        </div>
      </Col>
    );
  }

  return (
    <Row className="assessment-summary">
      <div className="d-flex gap-2 mt-4">
        <h1>Assessment details</h1>{" "}
        {resultGet?.can_be_edited && (
          <Button className={"w-25"} edit={true} label={"Edit"} />
        )}
      </div>
      <p className="mt-4">
        Here are your health assessment details, related to{" "}
        {resultGet?.test_kit_details?.test_kit_name} (
        {resultGet?.test_kit_details?.test_kit_type}). Our doctor will prepare a
        report based on your answers. You can edit the assessment until the
        sample arrives at our lab.
      </p>
      {Object.keys(assessmentData).length > 0 ? ( // Check if assessmentData has content
        Object.keys(assessmentData).map(section =>
          assessmentData[section]
            ? renderSection(section, assessmentData[section])
            : null
        )
      ) : (
        <p>No assessment data available.</p> // Optional: handle no data case
      )}
    </Row>
  );
}
