import React from "react";
import { Line } from "rc-progress";
import check from "../../assets/icons/Icon.svg";

const StepProgressBar = ({
  steps,
  currentStep,
  isCompleted,
  completedSteps
}) => {
  const progressPercentage = (currentStep / steps) * 100;

  return (
    <div className="progress-bar-container">
      <Line
        percent={progressPercentage}
        strokeWidth="5"
        trailWidth="5"
        strokeColor="#49A49D"
        trailColor="#C6C6C6"
      />
      <div className="steps">
        {Array.from({ length: steps }).map((_, index) => (
          <div
            key={index}
            className={`step ${
              index === currentStep - 1 ||
              (index < completedSteps &&
                !(index < currentStep) && !(index < isCompleted))
                ? "current-step"
                : index < currentStep && index < isCompleted && "completed"
            }}`}
          >
            <a
              className={`step ${
                index === currentStep - 1 ||
                (index < completedSteps &&
                  !(index < currentStep) && !(index < isCompleted))
                  ? "current-step"
                  : index < currentStep && index < isCompleted && "completed"
              } }`}
            >
              {index < currentStep && index < isCompleted ? (
                <img src={check} className="completed-icon" />
              ) : (
                index + 1
              )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepProgressBar;
