import React from "react";
import "../../../../assets/scss/_marker-scale.scss";
import { renderRangeLabel } from "../../../../utils/reportUtils";

/**
 * BoundaryLabels component
 * This component renders labels for the boundaries of a marker scale.
 * It displays low, normal, and high boundary labels with optional styles
 * based on the provided props.
 * @returns {JSX.Element} The rendered boundary labels component.
 */

const BoundaryLabels = ({
  showLowBoundary,
  leftBarSize,
  barSize,
  normalBoundaryLabel,
  highBoundaryLabel,
  decision_type,
  type,
  lower_boundary,
  upper_boundary
}) => {
  return (
    <div className="markerScale__labels">
      {showLowBoundary && (
        <div
          style={{ width: `${leftBarSize || barSize}%` }}
          className="markerScale__label"
        >
          Low
        </div>
      )}
      <div style={{ width: `${barSize}%` }} className="markerScale__label">
        {normalBoundaryLabel}
        <div className="markerScale__label-range">
          {renderRangeLabel(
            decision_type,
            type,
            lower_boundary,
            upper_boundary
          )}
        </div>
      </div>
      <div style={{ width: `${barSize}%` }} className="markerScale__label">
        {highBoundaryLabel}
      </div>
    </div>
  );
};

export default BoundaryLabels;
