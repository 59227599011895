export const loginUrl = "/api/dashboard/login";

export const logoutUrl = "/api/phlebotomists-portal/logout-user/";

export const createAccountUrl = "/api/dashboard/dashboard-account";

export const resetPasswordUrl = "/api/dashboard/dashboard-forgot-password";

export const myResultUrl = "/api/dashboard/test-kits/";

export const setPasswordUrl = "/api/dashboard/dashboard-reset-password";

export const assessmentValidateUrl = "/api/dashboard/assessement/validate";

export const assessmentStatusUrl = "/api/dashboard/assessment/status/";

export const assessementFinalSubmit = "/api/dashboard/assessment/blood/";

export const testKitDetailsUrl = "http://localhost:3005/testKitDetails";

export const wellnessReportUrl = (id) => `/api/dashboard/report/dna/${id}`;
 
export const phlebotomySuccessUrl = "http://localhost:3005/phlebotomySuccessPage";

export const rejectBookingUrl = (bid) => `http://localhost:3005/rejectBooking=${bid}`;

export const countriesUrl = "/api/dashboard/countries";

export const editAccountDetailsUrl = (userId) => `/api/dashboard/edit-account-details/${userId}`;

export const summaryUrl = "/api/dashboard/summary/";

export const bookingUrl = "/api/dashboard/bookings/";

export const confirmTestTypeUrl = "/api/dashboard/test-type-confirm/";

export const phlebotomyGetConsentUrl = (bid) => `/api/dashboard/booking/consent/${bid}`;

export const phlebotomyPostConsentUrl = "/api/dashboard/consent/";

export const phlebotomyAssessmentStatusUrl = (bid) => `/api/dashboard/assessment/status/booking/${bid}`;

export const phlebotomyAssessementFinalSubmit = (bid) =>  `/api/dashboard/assessment/blood/booking/${bid}`;


export const dirtyFromConfirmation =
    "Are you sure you want to leave without saving changes? Any unsaved modifications will be lost.";
