import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const Pane = styled.div`
  flex: ${props => (props.isSidebar && "0 1 225px") || props.weight};
`;

export const SplitScreen = ({
  children,
  isSidebar,
  leftWeight,
  rightWeight = 1
}) => {
  const [left, right] = children;
  return (
    <Container className="d-flex flex-wrap">
      <Pane weight={leftWeight} className={isSidebar && "sidebar-col"}>
        {left}
      </Pane>
      <Pane weight={rightWeight} className={"content-col"}>
        {right}
      </Pane>
    </Container>
  );
};
