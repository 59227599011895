import React from "react";
import { Col, Row } from "react-bootstrap";
import { as_title, format_value } from "../../utils/utils";

// Displays list of items based on provided fields and data
const DisplayItems = ({ fields, data }) => (
  <Row>
    {fields.map(field => (
      <DisplayItem
        key={field.name}
        field_name={field.name}
        field={field}
        data={data}
      />
    ))}
  </Row>
);

// Renders single display item with its value and optional help text
const DisplayItem = ({ field_name, field, data }) => {
  const value = data[field_name];
  return (
    <Col md={6}>
      <div className="border-top pt-2 pb-4">
        <h4 className="mb-1">
          {field.label || field.title || as_title(field_name)}
        </h4>
        <h5 className="font-weight-normal">
          {format_value(value, field.fmt, field.choices)}
        </h5>
        {field.helpText && (
          <small className="text-muted">{field.helpText}</small>
        )}
      </div>
    </Col>
  );
};

export default DisplayItems;
