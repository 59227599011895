import React from "react";
import { Formik, Form } from "formik";
import { FormCheck, Button } from "react-bootstrap";
import FormInput from "./FormInput";
import PromptIfDirty from "./PromptIfDirty";

const CheckboxGroupForm = ({ fields, onSubmit, type, initialValues, blockRefresh }) => (
  <div>
    <Formik
      initialValues={{
        checked: initialValues?.condition || initialValues?.medications || [], // Pre-select checkboxes based on initialValues
        other_description:
          initialValues?.other_description || initialValues?.details || "" // Set other_description if available in initialValues
      }}
      enableReinitialize // Reinitialize form if initialValues change
      onSubmit={async values => {
        // Structure the form data based on selected checkboxes
        const medicalConditions = { ...values };
        const otherDetails = values.other_description;

        const formattedData =
          type === "medical_conditions"
            ? {
              medical_conditions: {
                conditions: medicalConditions,
                other_description: otherDetails
              }
            }
            : {
              medications: {
                medications: medicalConditions,
                other_description: otherDetails
              }
            };
        await onSubmit(formattedData);
      }}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form className="assessment-form">
          {blockRefresh && <PromptIfDirty />}
          {/* Iterate over each field */}
          {fields.map(field => {
            if (field.type === "checkbox") {
              return (
                <FormCheck
                  key={field.controlId}
                  type="checkbox"
                  id={field.controlId}
                  label={field.label}
                  name={field.group_name} // Set all checkboxes to 'checked' array
                  value={field.name} // Use the 'name' as value to distinguish each checkbox
                  onChange={handleChange} // Handle change for Formik
                  checked={values[field.group_name]?.includes(field.name)} // Reflect checked status based on initialValues
                  className={field.className}
                  aria-describedby={field.aria_describedby}
                />
              );
            } else if (
              field.type !== "button" &&
              field.type !== "submit" &&
              field.type !== "group-label"
            ) {
              return (
                <>
                  <FormInput
                    id={`input-${field.controlId}`}
                    key={field.controlId}
                    className="form-group"
                    type={field.type}
                    {...field}
                    name={field.name}
                    value={values[field.name]}
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    onChange={handleChange}
                  />
                </>
              );
            }
            return (
              <p className="mt-4">
                {field.type === "group-label" && field.label}
              </p>
            ); // Ignore non-checkbox types
          })}

          <Button type="submit" variant="primary" className="mt-2">
            {fields.find(field => field.type === "submit")?.label || "Submit"}
          </Button>
        </Form>
      )}
    </Formik>
  </div>
);

export default CheckboxGroupForm;
