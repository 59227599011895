import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// Assessment message - show if assessment can be edited
export function AssessmentMessage({ assessment, test_id, bId }) {
  // Show message only when assessment can be edited
  if (!assessment.canEditAssessment) return null;

  // If filled assessment, show edit message
  if (assessment.hasFilledAssessment) {
    return (
      <>
        <Alert className="mt-3" color="info" fade={false}>
          Feel free to edit your Health Assessment to provide the most accurate
          details for our doctor.
          <br />
          <Button
            tag={Link}
            to={`/assessment`}
            color="info"
            className="mt-2"
            size="sm"
          >
            Edit Assessment
          </Button>
        </Alert>
      </>
    );
  }

  // Otherwise - there are no assessment yet
  return (
    <>
      <Alert className="mt-3" color="warning" fade={false}>
        Please{" "}
        <strong>
          <Link to={`/assessment`} className="alert-link">
            complete your Health Assessment
          </Link>
        </strong>
        . Our doctor cannot review your results effectively without this
        information.
        <br />
        <Button
          tag={Link}
          to={`/assessment`}
          color="warning "
          className="mt-2"
          size="sm"
        >
          Fill Assessment
        </Button>
      </Alert>
    </>
  );
}
