import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { bloodFields, dnaFields, shFields } from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import { barcodeValidationSchema } from "../../stories/Forms/validationSchema";

/**
 * EnterBarcode component
 *
 * This component manages the input of a barcode and tracking number
 * for activating test kits. It handles validation based on the test type,
 * fetches necessary data from the server, and submits the activation request.
 * Depending on the results, it navigates to the appropriate next step.
 *
 * @returns {JSX.Element} The rendered component for entering barcode and tracking number.
 */

const EnterBarcode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { callApi, isLoading, serverError, resultGet, resultPost } = useApis();
  const [error, setError] = useState("");

  /**
   * Validates the input values based on the current path.
   * Returns appropriate validation messages for each test type.
   */

  const handleValidation = (values, currentPath) => {
    const validationMessages = {
      blood:
        "To proceed with activating your Blood Test Kit, please ensure that you have selected the appropriate option on the activation page.",
      sh:
        "To proceed with activating your Sexual Health Test Kit, please ensure that you have selected the appropriate option on the activation page.",
      dna: "Secondary_barcode must be null."
    };

    if (currentPath.includes("blood")) {
      const isLavenderValid = values.secondary_barcode_type === "lavender";
      if (values.secondary_barcode_id && !isLavenderValid)
        return validationMessages.blood;
      if (values.secondary_barcode_type && !isLavenderValid)
        return validationMessages.blood;
    } else if (currentPath.includes("sh")) {
      const isUrineValid = values.secondary_barcode_type === "urine";
      if (!values.secondary_barcode_id) return validationMessages.sh;
      if (!isUrineValid) return validationMessages.sh;
    } else if (currentPath.includes("dna") && values.secondary_barcode_id) {
      return validationMessages.dna;
    }
    return "";
  };

  useEffect(() => {
    setError(serverError);
  }, [serverError]);

  const handleSubmit = async values => {
    const getUrl = `/api/dashboard/check-test/${values.code.toUpperCase()}/`;
    callApi({}, getUrl, "GET");
    setError(serverError);
  };

  useEffect(() => {
    if (resultGet?.id) {
      const validationError = handleValidation(resultGet, location.pathname);
      if (validationError) {
        setError(validationError);
        return;
      }

      const postPayload = {
        code: resultGet.primary_barcode_id.toUpperCase(),
        activation_stage: "barcode",
        tracking_id: resultGet.trackingNumber
      };
      callApi(postPayload, "/api/dashboard/activating/", "POST");
      setError(serverError);
    }
  }, [resultGet, resultPost]);

  useEffect(() => {
    if (resultPost) {
      const navigateTo = resultGet.secondary_barcode_id
        ? `/secondary-barcode/${resultGet.primary_barcode_id}`
        : `/consent/${resultGet.primary_barcode_id}`;
      navigate(navigateTo);

    } else {
      setError(serverError);
    }
  }, [resultPost]);

  useEffect(() => {
    if (resultGet) {
      localStorage.setItem("type", resultGet.type);
      localStorage.setItem("primary_barcode_id", resultGet.primary_barcode_id);
      localStorage.setItem(
        "secondary_barcode_id",
        resultGet.secondary_barcode_id || null
      );
    }
  }, [resultGet]);

  const getFormFields = () => {
    const path = location.pathname;
    if (path.includes("blood")) return bloodFields;
    if (path.includes("sh")) return shFields;
    if (path.includes("dna")) return dnaFields;
    return [];
  };

  return (
    <div className="row justify-content-center pt-4 enter-barcode-page">
      <div className="mt-3 p-0 col-10">
        <h1 className="mb-4"> Enter your barcode &amp; tracking number</h1>
        <p className="text-muted">
          Please enter the 10 character barcode as it appears on the small
          sticker attached to the inside of your instructions for use leaflet.
        </p>
        <p className="text-muted">
          To ensure the stability of your sample for processing, you should only
          collect and return your sample<strong>– Monday – Thursday</strong>. Do
          not collect or return your samples on a weekend or Bank Holiday as
          there will be postal delays.
        </p>
        {/* Display loading spinner if the request is in progress */}
        {isLoading && <div>Loading...</div>}

        <DynamicForm
          fields={getFormFields()}
          validationSchema={barcodeValidationSchema}
          className={"barcode-form"}
          onSubmit={handleSubmit}
          validationError={error}
        />
      </div>
    </div>
  );
};

export default EnterBarcode;
