import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Form, InputGroup } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Markdown } from "@storybook/blocks";
import { Recaptcha } from "../../components/Recaptcha";
import ToggleButtons from "../Buttons/ToggleButtons";
import { handleTrackingNumberChange, handleChangeWithContext, getDateRangeForPicker } from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { Eye, EyeSlash, InfoCircleFill } from "react-bootstrap-icons";


function FormInput(props) {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateField
  } = useFormikContext();
  const [inputValue, setInputValue] = useState(values[props.name] || "");
  const [originalHeightValue, setHeightOriginalValue] = useState(
    values["height"] || ""
  );
  const [originalWeightValue, setWeightOriginalValue] = useState(
    values["weight"] || ""
  );
  const [originalUnits, setOriginalUnits] = useState(values["units"] || "");
  const [password, setPassword] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  // Conversion functions
  const convertToMetric = values => {
    if (values.units !== originalUnits) {
      return {
        height: values.height ? (values.height * 2.54).toFixed(2) : "",
        weight: values.weight ? (values.weight / 2.205).toFixed(2) : ""
      };
    }
    return null; // Return the original values if the units are already metric
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const convertToImperial = values => {
    if (values.units !== originalUnits) {
      return {
        height: values.height ? (values.height / 2.54).toFixed(2) : "",
        weight: values.weight ? (values.weight * 2.205).toFixed(2) : ""
      };
    }
    return null; // Return the original values if the units are already imperial
  };

  useEffect(() => {
    // Ensure the input value is in sync with the Formik value
    setInputValue(values[props.name] || "");

    if (props.name === "phone_number_primary" && !values[props.name]) {
      setInputValue("+44 ");
      setFieldValue(props.name, "+44 ");
    }

    if (auth?.units !== values.units) {
      if (auth.unitType === "Metric" && auth.toggled) {
        if (originalUnits !== "Metric") {
          const metricConverted = convertToMetric({
            height: values.height,
            weight: values.weight
          });
          if (props.name === "height") {
            setInputValue(metricConverted.height);
            setFieldValue(props.name, metricConverted.height);
          } else if (props.name === "weight") {
            setInputValue(metricConverted.weight);
            setFieldValue(props.name, metricConverted.weight);
          }
        } else {
          if (props.name === "height") {
            setInputValue(originalHeightValue);
            setFieldValue(props.name, originalHeightValue);
          } else if (props.name === "weight") {
            setInputValue(originalWeightValue);
            setFieldValue(props.name, originalWeightValue);
          }
        }
      } else if (auth.unitType === "Imperial" && auth.toggled) {
        const imperialConverted = convertToImperial({
          height: values.height,
          weight: values.weight
        });
        if (originalUnits !== "Imperial") {
          if (props.name === "height") {
            setInputValue(imperialConverted.height);
            setFieldValue(props.name, imperialConverted.height);
          } else if (props.name === "weight") {
            setInputValue(imperialConverted.weight);
            setFieldValue(props.name, imperialConverted.weight);
          }
        } else {
          if (props.name === "height") {
            setInputValue(originalHeightValue);
            setFieldValue(props.name, originalHeightValue);
          } else if (props.name === "weight") {
            setInputValue(originalWeightValue);
            setFieldValue(props.name, originalWeightValue);
          }
        }
      }
    }
  }, [auth, originalHeightValue, originalWeightValue, originalUnits]);

  const combinedErrors = {
    ...errors,
    ...(props.name === "code" && {
      [props.name]: props.validationError || errors[props.name]
    })
  };

  const handleBlur = (e) => {
    const fieldName = props.name;
    const fieldValue = e.target.value;

    // if (props.useLocalStorage) {
    //   localStorage.setItem(fieldName, fieldValue);
    // }

    // if (props.name === "primary_barcode") {
    //   const formattedBarcode = formatBarcode(fieldValue);
    //   setInputValue(formattedBarcode);

    //   props.onChange({
    //     ...e,
    //     target: { ...e.target, value: formattedBarcode },
    //   });
    // }

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleChange = e => {
    if (!props.readOnly && !props.disabled) {
      const newValue = e.target.value;
      setInputValue(newValue);
      // setOriginalValue(newValue); // Update the original value
      props.onChange(e);

      if (props.type === "password") {
        setPassword(newValue);
      }
    }
  };

  const handlePhoneNumberChange = e => {
    const input = e.target.value;

    // Allow empty input or numeric characters only
    if (input === "") {
      setInputValue("");
      setFieldValue(props.name, "");
      return;
    }

    // Remove non-numeric characters and preserve the +44
    const numericInput = input.replace(/[^\d+]/g, "");

    // Check for the presence of +44
    let formattedNumber = numericInput;
    if (!formattedNumber.startsWith("44")) {
      formattedNumber = "44" + formattedNumber; // Prepend 44 if not present
    }

    // Format number to +44 XXXX XXXX XXXX
    if (formattedNumber.startsWith("44")) {
      const prefix = "+44 ";
      const areaCode = formattedNumber.substring(2, 6); // 4444
      const mainNumber = formattedNumber.substring(6, 12); // Remaining digits
      formattedNumber =
        prefix + areaCode + (mainNumber ? " " + mainNumber : "");
    }

    // Allow deletion of characters
    setInputValue(formattedNumber);
    setFieldValue(props.name, formattedNumber);
  };

  return (
    <>
      {!props.hide && (
        <Form.Group className={"form-group"} key={props.key} controlId={props.controlId || props.name}>
          {props.image && (
            <div>
              <img
                src={props.image}
                alt="Field visual aid"
                className={"form-image mb-2" + props.imgClassname}
              />
            </div>
          )}
          <Form.Label className="labels">
            {props.required ? (
              <>
                {!props.noLabel && props.label}
                <span className="required"></span>
              </>
            ) : (
              !props.noLabel && props.label
            )}
          </Form.Label>

          {props.type === "radio"  ? (
            <div>
              {props.choices.map((choice, index) => (
                <Form.Check
                  type="radio"
                  key={index}
                  id={`${props.name}-${choice.value}`}
                  name={props.name}
                  value={choice.value}
                  label={choice.label}
                  checked={inputValue === choice.value}
                  onChange={handleChange}
                  required={props.required}
                  isInvalid={props.touched && !!props.error}
                />
              ))}
            </div>
          ) : props.type === "checkbox" && props.name === "consent_steps" ? (
            <Form.Check
              type="checkbox"
              id={`default-${props.id}`}
              label={
                <>
                  <strong>{props.heading}</strong>
                  <br />
                  {props.message && (
                    <small className="form-text text-muted">
                      {props.message}
                    </small>
                  )}
                </>
              }
              checked={props.checked}
              onChange={props.onChange}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          ) : props.type === "checkbox" ? (
            <Form.Check
              key={props.controlId}
              type="checkbox"
              id={props.controlId}
              label={props.label}
              name={props?.group_name} // Set all checkboxes to 'checked' array
              value={props.name} // Use the 'name' as value to distinguish each checkbox
              onChange={props?.onChange}// Handle change for Formik
              checked={props?.values?.checked?.includes(props.name)} // Reflect checked status based on initialValues
              className={props.className}
              aria-describedby={props.aria_describedby}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          ) : props.type === "select" ? (
            <Form.Select
              aria-label="Default select example"
              placeholder={props.placeholder}
              value={props.value}
              isInvalid={props.touched && !!props.error}
              {...props}
              onChange={handleChange}
            >
              <option>{props.placeholder}</option>
              {props.options.map((option, index) => (
                <option key={index} value={option?.value || option}>
                  {option?.label || option}
                </option>
              ))}
            </Form.Select>
          ) : props.type === "toggle-buttons" ? (
            <>
              <div className="form-group toggle-btn-group">
                <ToggleButtons
                  field={props}
                  toggleValueProp={props.toggleValueProp}
                  noLabel={true}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <small className="form-text text-muted mt-2">
                  {props.helpText}
                </small>
              </div>
            </>
          ) : (
            <>
              {props.type !== 'password' && <Form.Control
                id={props.controlId || props.name}
                type={props.type}
                name={props.name}
                as={(props.as && props.as) || Form.Control}
                required={props.required}
                placeholder={props.placeholder}
                size={props.size}
                readOnly={props.readOnly}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                value={
                  props.name === "secondary_barcode"
                    ? localStorage.getItem("secondary_barcode_id") || "" // Use an empty string if null
                    : inputValue
                }
                aria-describedby={props.aria_describedby}
                isInvalid={
                  props.validationError ||
                  (props.touched && !!props.error) ||
                  !!(touched[props.name] && combinedErrors[props.name])
                }
                onChange={e => {
                  // Check if the field is sample_collection_date
                  if (props.name === "sample_collection_date" || props.type === "sample_collection_date") {
                    const { min, max } = getDateRangeForPicker(); // Get the date range for 15 days ago to today
                    e.target.setAttribute("min", min); // Dynamically set the min date
                    e.target.setAttribute("max", max); // Dynamically set the max date
                  }

                  // Call the existing handle change function
                  handleChangeWithContext(
                    e,
                    props,
                    setInputValue,
                    setFieldValue,
                    handleTrackingNumberChange,
                    handlePhoneNumberChange,
                    auth
                  );
                }}
                onBlur={props.onBlur}
              />
              }</>
          )}
          {props.type === "password" && (
            <InputGroup>
              <Form.Control
                aria-describedby={props.aria_describedby}
                className="password-field"
                as={props?.as || Form.Control}
                disabled={props.disabled}
                isInvalid={props.touched && !!props.error}
                name={props.name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                required={props.required}
                size={props.size}
                type={showPassword ? "text" : "password"}
                value={password}
              />

              <InputGroup.Text
                className="password-input"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                <span>{showPassword ? <Eye /> : <EyeSlash />}</span>
              </InputGroup.Text>
            </InputGroup>
          )}
          {props.type === "recaptcha" && (
            <Recaptcha
              className={props.recaptchaClassName}
              onChange={props.recaptchaOnChange}
              elementId={props.recaptchaElementId}
            />
          )}
          {combinedErrors[props.name] && (
            <Form.Control.Feedback type="invalid">
              {combinedErrors[props.name]}
            </Form.Control.Feedback>
          )}
          {props.helpText && (
            <Form.Text
              className={props.helpTextClass}
              id={props.aria_describedby}
              muted={props.muted}
            >
              {props.helpText && <Markdown>{props.helpText}</Markdown>}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  as: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  aria_describedby: PropTypes.string,
  helpText: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  helpTextClass: PropTypes.string,
  valueName: PropTypes.string // Added prop for the field name to update on unit change
};

FormInput.defaultProps = {
  as: undefined,
  required: false,
  placeholder: "",
  size: undefined,
  readOnly: false,
  disabled: false,
  aria_describedby: undefined,
  helpText: "",
  image: "",
  helpTextClass: ""
};

export default FormInput;
