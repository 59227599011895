import React from "react";
import ToggleButtons from "../../stories/Buttons/ToggleButtons";
import { Col, Row, Container } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Button } from "../../stories/Buttons/Button";
import useApis from "../../services/useAPI";

const WellnessAssessment = () => {
  const id = 1;
  const url = `assessment/dna/${id}`;

  const DNAAsstFields = [
    {
      name: "milk",
      label: "Milk and dairy",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "nuts",
      label: "Nuts",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "gluten",
      label: "Gluten",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "shellfish",
      label: "Shellfish",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "oilyFish",
      label: "Oily Fish",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "eggs",
      label: "Eggs",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "poultry",
      label: "Poultry",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "redMeat",
      label: "Red Meat excluding Pork",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "pork",
      label: "Pork",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    },
    {
      name: "alcohol",
      label: "Alcohol",
      options: [
        { label: "Don't Eat", value: "don'teat" },
        { label: "Eat", value: "eat" }
      ]
    }
  ];

  const { callApi } = useApis();

  const handleSubmitNew = values => {
    callApi({ values }, url, "POST");
  };

  const initialValues = DNAAsstFields.reduce((acc, field) => {
    acc[field.name] = ""; // Default value for each field
    return acc;
  }, {});

  return (
    <div className="row justify-content-center pt-4 wellness-assessment-page">
      <div className="mt-3 p-0 col-10">
        <h1 className="mb-4">Wellness DNA Assessment</h1>
        <div className="text-dark font-weight-bold">
          Please provide some basic details about yourself. We'll use this to
          customize your reports.
        </div>
        <p className="std-top-md">
          For the following please tell us which you can't eat (allergy or
          religious restriction), don't eat, or those you eat.
        </p>

        <Formik initialValues={initialValues} onSubmit={handleSubmitNew}>
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Container fluid className="p-0">
                <Row>
                  {DNAAsstFields.map((field, index) => (
                    <Col sm={6} md={6} xl={4} xs={12} key={index}>
                      <ToggleButtons
                        field={field}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  ))}

                  <Col
                    sm={12}
                    md={12}
                    xl={12}
                    xs={12}
                    className="d-flex justify-content-end"
                  >
                    <div className="btn-group">
                      <Button
                        label={"Skip"}
                        size={"small"}
                        className={"capsule-left-button btn-secondary"}
                      />
                      <Button
                        label={"Submit"}
                        type="submit" // Ensure this button is type="submit"
                        size={"small"}
                        className={"capsule-right-button"}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WellnessAssessment;
