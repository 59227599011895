import React, { useState } from "react";
import {
  faCheck,
  faChevronRight,
  faExclamation
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-bootstrap";
import "../../../assets/scss/_mobile-health-area.scss";
import HealthAreaSection from "./HealthAreaSection";

/**
 * MobileHealthArea component for displaying a health area in a mobile-friendly format.
 * @returns {JSX.Element} The rendered mobile health area component.
 */

export function MobileHealthArea({
  expanded = false,
  collapsible = true,
  area,
  area_status,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(expanded);

  const toggle = () => setIsOpen(!isOpen);

  const statusIcon = area_status === "good" ? faCheck : faExclamation;

  if (!collapsible) {
    return <HealthAreaSection {...props} hideHeader collapseItems={false} />;
  }

  return (
    <div className={`mobileHealthArea__container ${isOpen ? "is-opened" : ""}`}>
      <button className="mobileHealthArea__header" onClick={toggle}>
        <h3 className="mobileHealthArea__header-title">
          <span
            className={`mobileHealthArea__header-status mobileHealthArea__header-status--${area_status}`}
          >
            <FontAwesomeIcon icon={statusIcon} />
          </span>
          {area}
        </h3>
        <div className="mobileHealthArea__toggle">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
      <Collapse in={isOpen}>
        <div className="mobileHealthArea__main">
          <HealthAreaSection {...props} hideTitle hideIcon collapseItems />
        </div>
      </Collapse>
    </div>
  );
}
