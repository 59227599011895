// src/components/AppointmentInfo.jsx (optional file if you want to separate it)
import React from "react";
import { Alert, Button, Container, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Calendly from "./Calendly";

export function AppointmentInfo({
  user_time,
  note,
  notes,
  meet_details,
  show_button,
  event_status,
  test_id,
  updatePostData,
  meetingDetails,
  handleEdit,
  displayCalendly
}) {
  return (
    <div>
      <p>
        Thank you for scheduling your appointment to speak with one of our
        doctors.
        <span className={event_status === "reschedule_2" ? "d-none" : ""}>
          In the event that you need to reschedule your appointment, we kindly
          request that you use the button below to do so within{" "}
          <strong>24 hours</strong> prior to your appointment.
        </span>
      </p>

      <h3>Appointment Details</h3>
      <>
        <strong>Date & Time: &nbsp;</strong>
        {user_time === null || meet_details === null ? (
          <Spinner animation="border" />
        ) : (
          user_time || meet_details
        )}
      </>
      <br />
      <p>
        <strong>
          Our doctor will contact you at the telephone number you have provided
        </strong>
      </p>

      {show_button
        ? event_status !== "reschedule_2" && (
            <Button variant="primary" className="m-2" onClick={handleEdit}>
              Edit Appointment
            </Button>
          )
        : event_status !== "reschedule_2" && (
            <Button variant="primary" onClick={handleEdit} disabled>
              Edit Appointment
            </Button>
          )}
      {(note || notes) && (
        <Alert className="m-2" color="warning">
          <ReactMarkdown>{notes || note}</ReactMarkdown>
        </Alert>
      )}
      <Container className={displayCalendly}>
        <Calendly
          id={test_id}
          event_status={event_status}
          displayCalendly={displayCalendly}
          updatePostData={updatePostData}
          meetingDetails={meetingDetails}
        />
      </Container>
      <br />
      <br />
    </div>
  );
}
