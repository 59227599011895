import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../views/Account/LoginPage";
import ResetPassword from "../views/Account/ResetPassword";
import SetPassword from "../views/Account/SetPassword";
import Dashboard from "../views/Dashboard/Dashboard";
import CreateAccount from "../views/Account/CreateAccount";
import ConfirmAccount from "../views/Account/ConfirmAccount";
import ActivateTestKit from "../views/ActivateTestKit";
import TestList from "../views/TestsList";
import TestKitDetails from "../views/TestDetails/TestKitDetails";
import EnterBarcode from "../views/Activation/EnterBarcode";
import ConfirmDetails from "../views/Activation/ConfirmDetails";
import { Consent } from "../views/Activation/Consent";
import SecondaryBarcode from "../views/Activation/SecondaryBarcode";
import EssenTialTips from "../views/Activation/EssentialTips";
import TimeAdministered from "../views/Activation/TimeAdministered";
import ShConsent from "../views/Activation/ShConsent";
import WellnessCollectSample from "../views/Activation/WellnessCollectSample";
import BloodReportPage from "../views/Bloods/Reports/BloodReportPage";
import { BloodMarkerModal } from "../views/Bloods/Reports/BloodMarkerModal";
import PrivateRoute from "../components/AuthContext/PrivateRoute";
import StartAssessment from "../views/Health Assessment/StartAssessment";
import Account from "../views/Account/Account";
import ResumeActivateBlood from "../views/Activation/ResumeActivateBlood";
import WellnessReportPage from "../views/WellnessReports/WellnessReportPage";
import PhlebotomyDashboard from "../views/Phlebotomy/PhlebotomyDashboard";
import ConfirmTestType from "../views/Phlebotomy/ConfirmTestType";
import PhlebotomyStartAssessment from "../views/Phlebotomy/PhlebotomyStartAssessment";
import SuccessPage from "../views/Phlebotomy/SuccessPage";
import { SimpleAssessmentSummary } from "../views/Health Assessment/DetailsPage";
import WellnessAssessment from "../views/Health Assessment/WellnessAssessment";
import { useAuth } from "../components/AuthContext/AuthContext";
import setupInterceptors from "../services/setupInterceptors";
import WellnessStart from "../views/Health Assessment/WellnessStart";

export default () => {

  const auth = useAuth();
  const authContext = useAuth();

  // setupInterceptors(auth, authContext);
  return (
    <Routes>
      <Route element={<PrivateRoute element={Dashboard} />} path="/" />
      <Route
        element={<PrivateRoute element={ActivateTestKit} />}
        path="/activate"
      />
      <Route element={<LoginPage />} path="/login" />
      <Route element={<ResetPassword />} path="/reset-password" />
      <Route element={<SetPassword/>} path="/reset-password/:token/:id" />
      <Route
        element={<CreateAccount />} path="/create-account"
      />
      <Route element={<ConfirmAccount />} path="/create-account/:id" />

      {/* MyResult Routes */}
      <Route element={<PrivateRoute element={TestList} />} path="/tests" />
      <Route
        element={<PrivateRoute element={TestKitDetails} />}
        path="/test-kits/:test_kit_id/"
      />

      {/* Return to My Activation routes */}
      <Route
        element={<PrivateRoute element={ResumeActivateBlood} />}
        path="/test-kits/resume/:test_kit_id/"
      />

      {/* Activation routes */}
      <Route
        element={<PrivateRoute element={ActivateTestKit} />}
        path="/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/bloods/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/sh/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/dna/activate"
      />
      <Route
        element={<PrivateRoute element={ConfirmDetails} />}
        path="/confirm-details/:primaryBarcode"
      />
      <Route element={<PrivateRoute element={Consent} />} path="/consent/:primaryBarcode" />
      <Route
        element={<PrivateRoute element={SecondaryBarcode} />}
        path="/secondary-barcode/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={EssenTialTips} />}
        path="/essential-tips/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={TimeAdministered} />}
        path="/time-administered/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={ShConsent} />}
        path="/sh-consent/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={WellnessCollectSample} />}
        path="/wellness-collect-sample/:primaryBarcode"
      />

      {/* Reports routes */}
      <Route
        element={<PrivateRoute element={BloodReportPage} />}
        path="/bloods-report"
      />
      <Route
        element={<PrivateRoute element={BloodMarkerModal} />}
        path="/bloods-modal"
      />
      <Route
        element={<PrivateRoute element={WellnessReportPage} />}
        path="/wellness-report/:id"
      />
      
      {/* MyAccount routes */}
      <Route element={<PrivateRoute element= {Account} />} path="/account" />

      {/* Phlebotomy routes */}
      <Route element={<PrivateRoute element= {PhlebotomyDashboard}/>} path="/bloods/boots/:test_type"/>
      <Route element={<PrivateRoute element= {ConfirmTestType}/>} path="/bloods/boots/registration/:test_type"/>
      <Route element={<PrivateRoute element={Consent} />} path="/bloods/boots/:test_type/booking/consent/:bid" />
      <Route element={<PrivateRoute element={ConfirmDetails} />}path="/bloods/boots/:test_type/confirm-details/:bid" />
      <Route element={<PrivateRoute element= {PhlebotomyStartAssessment}/>} path="/bloods/boots/assessment/:test_type/:bid"/>
      <Route element={<PrivateRoute element={StartAssessment} />} path="/bloods/boots/assessment-form/:test_type/:bid" />
      <Route element={<PrivateRoute element={SimpleAssessmentSummary} />} path="/bloods/boots/assessment-details/:test_type/:bid" />
      <Route element={<PrivateRoute element= {SuccessPage}/>} path="/bloods/boots/success-page/:test_type/:bid"/>

      {/* Health assessment routes */}
      <Route element={<PrivateRoute element={WellnessAssessment} />} path="/wellness-assessment" />
      <Route element={<PrivateRoute element={WellnessStart} />} path="/assessment/wellness" />
    </Routes>
  );
};
