import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Toaster = props => {
  // Array to hold multiple toasts
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (props.newToast) {
      // Adds a new toast whenever props.newToast is provided
      const id = new Date().getTime(); // Unique ID for each toast
      setToasts([...toasts, { ...props.newToast, id }]);
      // Automatically hides the toast after a timeout
      setTimeout(() => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
      }, props.autoHideDuration || 3000); // Use the autoHideDuration or fallback to 3000ms
    }
  }, [props.newToast]);

  const handleClose = id => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  return (
    <ToastContainer className="position-fixed" position={props.position}>
      {toasts.map(toast => (
        <Toast
          className="rounded-0"
          key={toast.id}
          show={true}
          onClose={() => handleClose(toast.id)}
        >
          <Toast.Header>
            {toast.icon ? (
              <span className="me-1">{toast.icon}</span>
            ) : (
              <img src={toast.imgSrc || ""} className="rounded me-2" alt="" />
            )}
            <strong className="me-auto mt-1">{toast.header}</strong>
          </Toast.Header>
          <Toast.Body>{toast.body}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

/* Define PropTypes for the Toaster component */
Toaster.propTypes = {
  /**
   * New toast content
   */
  newToast: PropTypes.shape({
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    imgSrc: PropTypes.string
  }),
  /**
   * Auto hide duration for the toast
   */
  autoHideDuration: PropTypes.number,
  /**
   * Define position of the Toaster
   */
  position: PropTypes.string
};

Toaster.defaultProps = {
  position: "top-end", // Default position for the toaster
  autoHideDuration: 3000 // Default auto hide duration
};

export default Toaster;
