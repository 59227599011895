import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "../Buttons/Button";

function ConfirmationModal({
  heading,
  message,
  show,
  handleClose,
  handleConfirm,
  onCancelClick,
  hideButtons,

  ...props
}) {
  const handleCancelAndClose = () => {
    onCancelClick();
    handleClose();
  };

  const handleClick = () => {
    props.variantTwo === "danger" ? handleCancelAndClose() : handleConfirm();
  };

  return (
    <Modal onHide={handleClose} show={show} top>
      {/* <Modal.Header closeButton>
 <Modal.Title>{heading}</Modal.Title>
 </Modal.Header> */}
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {!hideButtons && (
          <>
            <div className="btn-group">
              <Button
                label={props.labelOne}
                onClick={
                  props.vairantOne === "danger" ? handleClose : handleClick
                }
                size={"small"}
                variant={props.vairantOne}
                className={"capsule-left-button btn-secondary"}
              />
              <Button
                label={props.labelTwo}
                onClick={
                  props.vairantOne === "danger" ? handleClick : handleClose
                }
                size={"small"}
                variant={props.variantTwo}
                className={"capsule-right-button"}
              />
            </div>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  vairantOne: PropTypes.string,
  vairantTwo: PropTypes.string,
  labelOne: PropTypes.string,
  labelTwo: PropTypes.string,
  hideButtons: PropTypes.bool
};

ConfirmationModal.defaultProps = {};

export default ConfirmationModal;
