import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import useApis from "../../services/useAPI";

export default function ResumeActivateBlood() {
  const { test_kit_id } = useParams();
  const navigate = useNavigate();

  // API hook
  const { callApi, resultGet, isLoading, serverError } = useApis();

  useEffect(() => {
    callApi(null, `/api/dashboard/test-kits/resume/${test_kit_id}`, "GET");

    if (resultGet) {
      const {
        activation_stage,
        barcode,
        secondary_type,
        secondary_barcode
      } = resultGet;

      // Navigates based on the activation stage received from the API response
      switch (activation_stage) {
        case "barcode":
          if (secondary_type !== null) {
            navigate(
              `/tests/private/confirm/${secondary_type}/${barcode}?secondary_barcode=${secondary_barcode}`
            );
          } else {
            navigate(`/tests/private/consent/${barcode}/`);
          }
          break;
        case "confirm-barcode":
          navigate(`/tests/private/consent/${barcode}/`);
          break;
        case "consent":
          navigate(`/tests/bloods/activate/${barcode}/`);
          break;
        case "personal-details":
          navigate(`/tests/private/collect-samples/${test_kit_id}/`);
          break;
        case "collect-sample":
          navigate(`/tests/private/time-administered/${barcode}/`);
          break;
        case "test-taken":
          navigate(`/assessment/${test_kit_id}/1`);
          break;
        default:
          navigate("/tests/bloods/activate");
          break;
      }
    }
  }, [test_kit_id, resultGet]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  // Shows error message if any
  if (serverError) {
    return <div className="alert alert-danger">Error: {serverError}</div>;
  }

  return null;
}
