import React, { useCallback, useState } from "react";
import { Alert, Spinner, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { AppointmentInfo } from "./AppointmentInfo";
import Calendly from "./Calendly";

const AppointmentDetails = ({
  isSexualHealthTest,
  unconfirmedResult,
  archive_reason_message,
  calendlyStatus,
  showAppointmentDetails,
  appointmentMissed,
  unconfirmedResultMessage,
  note,
  notes,
  testId,
  eventStatus,
  meetingDetails,
  userTime,
  meetDetails,
  showButton,
  renderInstructions,
  scheduleAttempt,
  consultationStatus,
  outcome,
  attemptType,
  testName
}) => {
  console.log(archive_reason_message);

  const [event_status, setEvent_status] = useState(eventStatus);
  const [schedule_attempt, setSchedule_Attempt] = useState(scheduleAttempt);
  const [meet_details, setMeet_details] = useState(meetDetails);
  const [notesState, setNotes] = useState(null); // Renamed to avoid conflict
  const [displayCalendly, setdisplayCalendly] = useState(`timeline__step--display`)


  const toggleAppointmentDetails = useCallback(() => {
    if (calendlyStatus !== null && consultationStatus !== "consulted") {
      if (!(outcome === "not_answered" || outcome === "not_attended")) {
        showAppointmentDetails = true;
      } else {
        showAppointmentDetails = false;
      }
    }
  }, [schedule_attempt, attemptType, outcome, calendlyStatus]);

  const handleEdit = async () => {
    setdisplayCalendly(`p-2`)
  }

  // To update date & time when event gets rescheduled
  const updatePostData = useCallback(
    (attempts, start_time, description, status) => {
      if (calendlyStatus === null) {
        calendlyStatus = "scheduled";
        toggleAppointmentDetails();
        isAppointmentMissed();
      }
      setEvent_status(status);
      setSchedule_Attempt(description);
      setMeet_details(start_time);
      setNotes(attempts);
      setdisplayCalendly(`timeline__step--display`);
      // if (appointmentMissed) autoReload();
    },
    [meet_details, event_status, schedule_attempt, displayCalendly, notesState]
  );

  // To show when user misses scheduled Calendly appointment
  const isAppointmentMissed = useCallback(() => {
    if (calendlyStatus !== null || calendlyStatus !== "") {
      if (outcome === "not_answered" || outcome === "not_attended") {
        appointmentMissed = true;
      } else {
        appointmentMissed = false;
      }
    } else return false;
  }, [calendlyStatus, schedule_attempt, attemptType, outcome]);

  if (!isSexualHealthTest || !unconfirmedResult || archive_reason_message)
    return null;

  return (
    <>
      <div className="my-3 border-0">
        <h2 className="text-md-left">{testName}</h2>
        {archive_reason_message && (
          <Alert variant="danger" dismissible={false}>
            <h1>Phone Consultation Required</h1>
            <ReactMarkdown>{archive_reason_message}</ReactMarkdown>
          </Alert>
        )}
        {calendlyStatus === null && !showAppointmentDetails ? (
          <>
            <Alert variant="danger" dismissible={false}>
              <h1>Phone Consultation Required</h1>
              <ReactMarkdown>{unconfirmedResultMessage}</ReactMarkdown>
            </Alert>
            {(note || notesState) && (
              <Alert variant="warning">
                <ReactMarkdown>{notesState || note}</ReactMarkdown>
              </Alert>
            )}
            <Container className="p-2">
              <Calendly
                id={testId}
                event_status={event_status}
                updatePostData={updatePostData}
                meetingDetails={meetingDetails}
              />
            </Container>
          </>
        ) : appointmentMissed === false && showAppointmentDetails ? (
          <>
            {/* {userTime || meetDetails ? ( */}
            <AppointmentInfo
              user_time={userTime}
              meet_details={meet_details}
              show_button={showButton}
              event_status={event_status}
              test_id={testId}
              note={note}
              notes={notesState}
              updatePostData={updatePostData}
              meetingDetails={meetingDetails}
              handleEdit={handleEdit}
              displayCalendly={displayCalendly}
            />
            {/* ) : (
              <Spinner animation="border" />
            )} */}
          </>
        ) : appointmentMissed ? (
          <>
            <Alert variant="danger" dismissible={false}>
              <h1>You Missed Your Appointment!</h1>
              <ReactMarkdown>{unconfirmedResultMessage}</ReactMarkdown>
            </Alert>
            {(note || notesState) && (
              <Alert className="mt-2" variant="warning">
                <ReactMarkdown>{notesState || note}</ReactMarkdown>
              </Alert>
            )}
            {scheduleAttempt !== "phone_3" && (
              <Container className="p-2">
                <Calendly
                  id={testId}
                  updatePostData={updatePostData}
                  event_status={event_status}
                  meetingDetails={meetingDetails}
                />
              </Container>
            )}
          </>
        ) : (
          renderInstructions()
        )}
      </div>
    </>
  );
};

export default AppointmentDetails;
