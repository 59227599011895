import Form from "react-bootstrap/Form";
import FormInput from "./FormInput";
import { Button } from "../Buttons/Button";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useRef, useState, useEffect, useCallback } from "react";
import { Recaptcha } from "../../components/Recaptcha";
import PasswordStrength from "../../components/PasswordStrength";
import ToggleButtons from "../Buttons/ToggleButtons";
import { FormGroup } from "react-bootstrap";
import { previousFifteenDays, today, todayFormatted, YoutubeEmbed } from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import PromptIfDirty from "./PromptIfDirty";

const DynamicForm = props => {
  const auth = useAuth();
  const recaptchaRef = useRef(null);
  const [toggleValue, setToggleValue] = useState(null);
  const [genderIdentity, setGenderIdentity] = useState(null);
  const [formData, setFormData] = useState(null);
  const [modifiedFields, setModifiedFields] = useState([]); // Track modified fields in state

  // Helper function to modify field properties as needed
  const modifyFields = (fields, initialValues) => {
    // Return the fields with modified properties based on conditions
    return fields?.map(field => {
      if (initialValues.smoking === "Yes") {
        if (field.name === "cigarettes_per_day") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.alcohol === "Yes") {
        if (field.name === "alcohol_units_per_week") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.physical_activity === "Yes") {
        if (field.name === "light_activity_hours_per_week") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.physical_activity === "Yes") {
        if (field.name === "moderate_activity_hours_per_week") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.physical_activity === "Yes") {
        if (field.name === "vigorous_activity_hours_per_week") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.sexually_active === "Yes") {
        if (field.name === "partners") {
          return { ...field, disabled: false, required: true, hide: false };
        }
        if (field.name === "recent_sex") {
          return { ...field, disabled: false, required: true, hide: false };
        }
        if (field.name === "had_unprotected_sex") {
          return { ...field, disabled: false, required: true, hide: false };
        }
        // had_unprotected_sex
      }
      if (initialValues.had_unprotected_sex === "Yes") {
        if (field.name === "unprotected_sex_type") {
          return { ...field, disabled: false, check_group_required: true, check_group_hide: false };
        }
      }
      if (initialValues.sti_symptoms === "Yes") {
        if (field.name === "sti_symptoms_description") {
          return { ...field, disabled: false, required: true, hide: false };
        }
      }
      if (initialValues.potential_sti_exposure === "Yes") {
        if (field.name === 'potential_sti_exposure') {
          return { ...field, check_group_required: true, check_group_hide: false };
        }
      }

      // Modify height and weight fields based on unit type
      if (field.name === "height") {
        return {
          ...field,
          label: `Please enter your height (${auth.unitType === "Metric" ? "cm" : "inch"
            }):`
        };
      } else if (field.name === "weight") {
        return {
          ...field,
          label: `Please enter your weight (${auth.unitType === "Metric" ? "kg" : "lbs"
            })`
        };
      }
      // }

      // Return the unmodified field if no conditions match
      return field;
    });
  };

  const generateInitialValues = fields => {
    const initialValues = {};
    fields?.forEach(field => {
      if (field.type !== "button" || field.type !== "submit") {
        initialValues[field.name] = "";
      }
    });
    return initialValues;
  };

  useEffect(() => {
    if (
      formData?.smoking === "Yes" ||
      formData?.alcohol === "Yes" ||
      formData?.physical_activity === "Yes" ||
      formData?.sexually_active === "Yes" ||
      formData?.had_unprotected_sex === "Yes" ||
      formData?.sti_symptoms === "Yes" ||
      formData?.potential_sti_exposure === "Yes"
    ) {
      setModifiedFields(modifyFields(props.fields, formData));
    } else if (
      formData?.smoking === "No" ||
      formData?.alcohol === "No" ||
      formData?.physical_activity === "No" ||
      formData?.sexually_active === "No" ||
      formData?.had_unprotected_sex === "No" ||
      formData?.sti_symptoms === "No" ||
      formData?.potential_sti_exposure === "No"
    ) {
      setModifiedFields(props.fields);
    }
    if (formData?.height || formData?.weight || formData?.units) {

      setModifiedFields(modifyFields(props.fields, formData));
    }
    if (props.initialValues?.units) {
      auth.setUnitType(props.initialValues?.units);
      setToggleValue(props.initialValues?.units);
    }
    if (props.initialValues?.sex) {
      setToggleValue(props.initialValues.sex);
    }
    if (typeof props.initialValues?.identify_with_gender === "boolean") {
      setGenderIdentity(
        props.initialValues.identify_with_gender ? "yes" : "no"
      );
    }
  }, [
    props.initialValues?.sex,
    props.initialValues?.identify_with_gender,
    formData
  ]);
  useEffect(() => {
  }, [modifiedFields, props.initialValues]);
  const fields = modifiedFields.length > 0 ? modifiedFields : props.fields; // Use modifiedFields if available

  return (
    <Formik
      validationSchema={props.validationSchema}
      onSubmit={(values, { resetForm }) => {
        console.log("Submitting values:", values);
        delete values.undefined;
        props.onSubmit && props.onSubmit(values);
        resetForm();
        if (recaptchaRef.current) {
          recaptchaRef.current.resetRecaptcha();
        }
      }}
      initialValues={
        props.initialValues ||
        generateInitialValues(props.modify ? modifiedFields : props.fields)
      }
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue
      }) => (
        setFormData(values),
        (
          
          <Form noValidate onSubmit={handleSubmit} className={props.className}>
            {props.blockRefresh && <PromptIfDirty />}
            {fields?.map((field, index) => {
              if (field.name === "sample_collection_time") {
                return null;
              }
              if (field.type === "iframe") {
                return (
                  <div className="mt-4 mb-4">
                    <Form.Group
                      key={field.controlId}
                      controlId={field.controlId}
                    >
                      <YoutubeEmbed embedId={field.embedId} />
                    </Form.Group>
                  </div>
                );
              }
              return (
                <Form.Group key={field.controlId} controlId={field.controlId}>

                  {field.type === "button" || field.type === "submit" ? (
                    <div
                      className={`d-flex justify-content-${field.linkName ? "between" : "end"
                        } align-items-center`}
                    >
                      {field.linkName && (
                        <a href={field.href} className="small-link">
                          {field.linkName}
                        </a>
                      )}
                      <div>
                        <p>{field?.aboveBtnMsg}</p>
                        <Button
                          type={field.type}
                          size={field.size}
                          className={`${field?.className} float-end`}
                          variant={field.variant}
                          label={field.label}
                        />
                      </div>
                    </div>
                  ) : field.type === "recaptcha" ? (
                    <Recaptcha
                      key={field.controlId}
                      ref={recaptchaRef}
                      onChange={response =>
                        setFieldValue("recaptcha_token", response)
                      }
                      className="recaptcha-container"
                    />
                  ) : field.type === "button-group" ? (
                    <div>
                      <div className={"form-group " + field?.className}>
                        <Form.Label className="labels">
                          {field.required ? (
                            <>
                              {field.label}
                              <span className="required"></span>
                            </>
                          ) : (
                            field.label
                          )}
                        </Form.Label>
                        <div
                          className={
                            props.btnSecondary
                              ? "d-flex flex-wrap align-items-center barcode-2-btns"
                              : props.genderButton
                                ? "d-flex flex-wrap align-items-center btn-group toggle-btn-group"
                                : "d-flex flex-wrap align-items-center"
                          }
                        >
                          {field?.buttons?.map(button => (
                            <>
                              {button.aboveBtnMsg && <><p>{button?.aboveBtnMsg}</p> <br /></>}
                              <Button
                                key={button.controlId}
                                type={button.type}
                                size={button.size}
                                className={button.className}
                                variant={button.variant}
                                label={button.label}
                                href={button.href}
                                active={button.active}
                                onClick={button.onClick}
                              />
                            </>
                          ))}
                        </div>
                        {field.helpText && (
                          <small className="form-text text-muted mt-2">
                            {field.helpText}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : field.type === "toggle-buttons" &&
                    field.name !== "units" ? (
                    <>
                      <div className="form-group toggle-btn-group">
                        <ToggleButtons
                          field={field}
                          toggleValueProp={toggleValue}
                          genderIdentityProp={genderIdentity}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                        />
                        <small className="form-text text-muted mt-2">
                          {field.helpText}
                        </small>
                      </div>
                    </>
                  ) : field.type === "help-text" ? (
                    <small className="form-text text-muted mt-2">
                      {field.helpText}
                    </small>
                  ) : field.name === "sample_collection_date" ? (
                    <div className="row" key={index}>
                      {props.fields
                        .filter(
                          f =>
                            f.name === "sample_collection_date" ||
                            f.name === "sample_collection_time"
                        )
                        ?.map((f, subIndex) => (
                          <FormGroup
                            className={`col-md-${f.colSize} ${f.className ||
                              ""}`}
                            key={subIndex}
                          >
                            <FormInput
                              id={`input-${f.controlId}`}
                              key={f.controlId}
                              min={previousFifteenDays}
                              max={todayFormatted}
                              maxlength={f.maxLength}
                              className={`form-group`} // Keeps "form-group" and appends f.className if it exists
                              type={f.type}
                              {...f}
                              name={f.name}
                              value={values[f.name]}
                              onChange={handleChange}
                              toggleValueProp={toggleValue}
                              error={errors[f.name]}
                              maxLength={f.maxLength}
                              touched={!!touched[field.name] ? "true" : "false"}
                            />
                          </FormGroup>
                        ))}
                    </div>
                  ) : field.name === "consent_steps" ? (
                    <FormInput
                      key={index}
                      type="checkbox"
                      name={field.name}
                      id={field.controlId}
                      heading={field.heading}
                      message={field.message}
                      checked={field.checked} // Pass the checked state
                      onChange={field.onChange} // Pass the onChange handler
                    />
                  ) : (
                    !field.check_box_group && <FormInput
                      id={`input-${field.controlId}`}
                      key={field.controlId}
                      className="form-group"
                      type={field.type}
                      {...field}
                      name={field.name}
                      maxLength={field.maxLength}
                      value={values[field.name]}
                      toggleValueProp={toggleValue}
                      onChange={e => {
                        handleChange(e);
                        if (field.type === "password") {
                          props.setPassword &&
                            props.setPassword(e.target.value);
                        }
                      }}
                      error={errors[field.name]}
                      touched={!!touched[field.name] ? "true" : "false"}
                      // touched={touched[field.name]}
                      image={field.image}
                      validationError={props.validationError}
                    />
                  )}
                  <div className="form-group">
                    {(field.check_group_label && !field.check_group_hide) && <Form.Label className='labels'>{field.check_group_label} <span className="required"></span></Form.Label>}
                    {(field.check_box_group && !field.check_group_hide) &&
                      field.check_box_group.map(
                        check =>
                          <FormInput
                            id={`input-${check.controlId}`}
                            key={check.controlId}
                            className="form-group"
                            type={check.type}
                            {...check}
                            name={check.name}
                            value={values[check.name]}
                            toggleValueProp={toggleValue}
                            onChange={e => {
                              handleChange(e);
                              if (check.type === "password") {
                                props.setPassword &&
                                  props.setPassword(e.target.value);
                              }
                            }}
                            error={errors[check.name]}
                            // touched={!!touched[check.name] ? "true" : "false"}
                            touched={touched[check.name]}
                            noLabel={true}
                            image={check.image}
                            validationError={props.validationError}
                          />
                      )

                    }
                  </div>
                  {field.type === "password" && props.password && (
                    <PasswordStrength password={props.password} />
                  )}
                </Form.Group>
              );
            })}
          </Form>
        )
      )}
    </Formik>
  );
};

export default DynamicForm;

DynamicForm.defaultProps = {
  fields: [],
  validationSchema: null,
  onSubmit: undefined
};

DynamicForm.propTypes = {
  validationSchema: PropTypes.object,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      controlId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      buttons: PropTypes.array,
      as: PropTypes.string,
      required: PropTypes.bool,
      placeholder: PropTypes.string,
      size: PropTypes.string,
      readOnly: PropTypes.bool,
      disabled: PropTypes.bool,
      aria_describedby: PropTypes.string,
      helpText: PropTypes.string,
      linkName: PropTypes.string,
      href: PropTypes.string,
      className: PropTypes.string,
      variant: PropTypes.string,
      FormFieldImage: PropTypes.node
    })
  ),
  onSubmit: PropTypes.func
};
